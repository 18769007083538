import querystring from "query-string";
import { useLocation, useNavigate } from "@reach/router";
import { QueryFieldType } from "./QueryFieldType";
import useURLSearchParams from "../../hooks/useURLSearchParams";

const useOnSearch = (field: QueryFieldType) => {
	const location = useLocation();
	const searchParams = useURLSearchParams();
	const navigate = useNavigate();

	return async (value: string) => {
		const query = Object.fromEntries(searchParams);
		if (value !== "") {
			query[field] = value;
		} else {
			delete query[field];
		}
		await navigate(`${location.pathname}?${querystring.stringify(query)}`);
	};
};

export default useOnSearch;
