import React, { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
`;

const EllipsisText: FC = ({ children }) => {
	return <Container>{children}</Container>;
};

export default EllipsisText;
