import React, { FC } from "react";
import IconButton from "@material-ui/core/IconButton";
import MaterialIcon from "@material-ui/icons/Edit";
import styled from "styled-components";

const Icon = styled(MaterialIcon)`
	color: #6c87c6;
`;

type Props = {
	className?: string;
	onClick: () => void;
};

const EditIcon: FC<Props> = ({ className, onClick }: Props) => {
	return (
		<IconButton
			className={className}
			component="span"
			size="medium"
			onClick={onClick}
		>
			<Icon />
		</IconButton>
	);
};

export default EditIcon;
