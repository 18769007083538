import React, { FC, Fragment } from "react";
import { Link } from "@reach/router";
import { path } from "../../routes/HomeRoute";
import UserAvatar from "../UserAvatar";
import { useMe } from "../../queries/me.query";

const MyUserAvatar: FC = () => {
	const me = useMe();

	if (me.isLoading) return <Fragment />;
	const { display_name, color } = me.data!.data;

	return (
		<Link data-test-id="link_me" to={path} style={{ textDecoration: "none" }}>
			<UserAvatar color={color} value={display_name} />
		</Link>
	);
};

export default MyUserAvatar;
