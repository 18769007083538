import _ from "lodash";
import React, { FC } from "react";
import Panel from "../Panel";
import BarWithLabelForField from "../BarWithLabelForField";
import getError from "./getError";
import { Props } from "./types";
import LeftAndRight from "./LeftAndRight";
import IELTSScoreSelect from "../IELTSScoreSelect";
import TOEFLScoreField from "../TOEFLScoreField";
import GridItem from "./GridItem";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { MyNumberField } from "./index";
import InlineEditText from "../InlineEditText";
import TextField from "../TextField";
import RadioButtons from "../RadioButtons";
import InlineEditMultiline from "../InlineEditMultiline";
import MultilineField from "../MultilineField";

const GapGrid = styled(Grid)`
	margin-left: 10px;

	.MuiFormControl-root:not(:last-of-type) {
		margin-right: 1rem;
	}
`;

const MajorOneLanguagePanel: FC<Props> = ({
	isEdit,
	errors,
	defaultValue: major,
	onChange,
}) => {
	const debounceValue = isEdit ? 500 : 0;

	return (
		<Panel title="語言成績">
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="優先顯示">
						<RadioButtons
							id="language_priority"
							checked={major.language_priority}
							label1="雅思"
							value1="IELTS"
							label2="托福"
							value2="TOEFL"
							onChange={(value) => onChange("language_priority", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="雅思">
						<IELTSScoreSelect
							id="ielts-overall"
							disabled={false}
							error={getError(errors, "ielts_overall")}
							defaultLabel="總成績"
							value={major.ielts_overall}
							onChange={(value) => onChange("ielts_overall", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GapGrid item xs={6}>
					<LeftAndRight>
						<IELTSScoreSelect
							id="ielts-listening"
							disabled={false}
							error={getError(errors, "ielts_listening")}
							defaultLabel="聽"
							value={major.ielts_listening}
							onChange={(value) => onChange("ielts_listening", value)}
						/>
						<IELTSScoreSelect
							id="ielts-speaking"
							disabled={false}
							error={getError(errors, "ielts_speaking")}
							defaultLabel="說"
							value={major.ielts_speaking}
							onChange={(value) => onChange("ielts_speaking", value)}
						/>
						<IELTSScoreSelect
							id="ielts-reading"
							disabled={false}
							error={getError(errors, "ielts_reading")}
							defaultLabel="讀"
							value={major.ielts_reading}
							onChange={(value) => onChange("ielts_reading", value)}
						/>
						<IELTSScoreSelect
							id="ielts-writing"
							disabled={false}
							error={getError(errors, "ielts_writing")}
							defaultLabel="寫"
							value={major.ielts_writing}
							onChange={(value) => onChange("ielts_writing", value)}
						/>
					</LeftAndRight>
					<div style={{ marginTop: 8 }}>
						備註
						{isEdit ? (
							<InlineEditMultiline
								id="ielts_notes"
								placeholder=""
								error={getError(errors, "ielts_notes")}
								defaultValue={major.ielts_notes}
								onConfirm={(value) => onChange("ielts_notes", value)}
							/>
						) : (
							<MultilineField
								id="ielts_notes"
								disabled={false}
								placeholder=""
								readOnly={false}
								error={getError(errors, "ielts_notes")}
								autoFocus={false}
								defaultValue={major.ielts_notes}
								onChange={(value) => onChange("ielts_notes", value)}
							/>
						)}
					</div>
				</GapGrid>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="托福">
						<MyNumberField
							id="toefl-overall"
							disabled={false}
							readOnly={false}
							autoFocus={false}
							error={getError(errors, "toefl_overall")}
							min={0}
							max={120}
							step={1}
							defaultValue={major.toefl_overall}
							onChange={_.debounce(
								(value) => onChange("toefl_overall", value),
								debounceValue
							)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GapGrid item xs={6}>
					<LeftAndRight>
						<TOEFLScoreField
							id="toefl-listening"
							disabled={false}
							readOnly={false}
							autoFocus={false}
							label="聽"
							error={getError(errors, "toefl_listening")}
							defaultValue={major.toefl_listening}
							onChange={_.debounce(
								(value) => onChange("toefl_listening", value),
								debounceValue
							)}
						/>
						<TOEFLScoreField
							id="toefl-speaking"
							disabled={false}
							readOnly={false}
							autoFocus={false}
							label="說"
							error={getError(errors, "toefl_speaking")}
							defaultValue={major.toefl_speaking}
							onChange={_.debounce(
								(value) => onChange("toefl_speaking", value),
								debounceValue
							)}
						/>
						<TOEFLScoreField
							id="toefl-reading"
							disabled={false}
							readOnly={false}
							autoFocus={false}
							label="讀"
							error={getError(errors, "toefl_reading")}
							defaultValue={major.toefl_reading}
							onChange={_.debounce(
								(value) => onChange("toefl_reading", value),
								debounceValue
							)}
						/>
						<TOEFLScoreField
							id="toefl-writing"
							disabled={false}
							readOnly={false}
							autoFocus={false}
							label="寫"
							error={getError(errors, "toefl_writing")}
							defaultValue={major.toefl_writing}
							onChange={_.debounce(
								(value) => onChange("toefl_writing", value),
								debounceValue
							)}
						/>
					</LeftAndRight>
				</GapGrid>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="語言課程網址">
						{isEdit ? (
							<InlineEditText
								id="language_website"
								placeholder="https://www.astar.tw"
								error={getError(errors, "language_website")}
								defaultValue={major.language_website}
								onConfirm={(value) => onChange("language_website", value)}
							/>
						) : (
							<TextField
								id="language_website"
								disabled={false}
								placeholder="https://www.astar.tw"
								readOnly={false}
								error={getError(errors, "language_website")}
								autoFocus={false}
								defaultValue={major.language_website}
								onChange={(value) => onChange("language_website", value)}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
		</Panel>
	);
};

export default MajorOneLanguagePanel;
