import React, { FC } from "react";
import KodenSelect from "../KodenSelect";
import { useFindAll } from "../../queries/resource.query";
import { UniversityRowType } from "../../types/universityType";

type Props = {
	value: string;
	onChange: (s: string) => void;
};

const UniversityKodenSelect: FC<Props> = ({ value = "", onChange }) => {
	const findAll = useFindAll<unknown, UniversityRowType[]>("university/list");
	let options = [{ id: "", value: "", label: "學校" }];

	if (findAll.isFetched) {
		const data = findAll.data!.map((e) => {
			return {
				id: e.id,
				label: e.name,
				value: e.id,
			};
		});
		options = options.concat(data);
	}
	return <KodenSelect options={options} value={value} onChange={onChange} />;
};

export default UniversityKodenSelect;
