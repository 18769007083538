import { path as LoginPath } from "./routes/LoginRoute";
import { IMG_PREFIX_URL } from "./config";

class Utils {
	static alert(str = "") {
		window.alert(str);
	}

	static locationHref(href = "") {
		window.location.href = href;
	}

	static refreshPage() {
		window.location.reload();
	}

	static redirectToLoginPage() {
		this.locationHref(LoginPath);
	}

	static isHttps(): boolean {
		return window.location.protocol === "https:";
	}

	static getAbsoluteImageUrl(src: string): string {
		return `${IMG_PREFIX_URL}/${src}`;
	}

	static removeAbsoluteImageUrl(src: string): string {
		if (src.startsWith(IMG_PREFIX_URL!, 0)) {
			return src.substring(IMG_PREFIX_URL!.length + 1, src.length);
		} else {
			return src;
		}
	}
}

export default Utils;
