import React, { ReactElement } from "react";
import "@fontsource/roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
	ThemeProvider,
	unstable_createMuiStrictModeTheme,
} from "@material-ui/core/styles";
import "./AppTheme.css";

const theme = unstable_createMuiStrictModeTheme({
	palette: {
		primary: {
			main: "#125e9f",
		},
	},
});

const AppTheme = ({ children }: { children: ReactElement }) => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</>
	);
};

export default AppTheme;
