function getCity(
	result: google.maps.GeocoderResult
): google.maps.GeocoderAddressComponent | undefined {
	const address_components = result.address_components;

	const postalTown = address_components
		.filter((e) => e.types.length === 1)
		.find((e) => e.types[0] === "postal_town");
	if (postalTown) {
		return postalTown;
	}

	const political = address_components
		.filter((e) => e.types.length === 2)
		.find((e) => {
			return e.types[0] === "locality" && e.types[1] === "political";
		});

	if (political) {
		return political;
	}

	const areaLevel1 = address_components
		.filter((e) => e.types.length === 2)
		.find((e) => {
			return (
				e.types[0] === "administrative_area_level_1" &&
				e.types[1] === "political"
			);
		});

	if (areaLevel1) {
		return areaLevel1;
	}
}

export default getCity;
