import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";

const useQueryParams = () => {
	const page = usePage();
	const urlSearchParams = useURLSearchParams();
	const country = urlSearchParams.get("country");
	const keyword = urlSearchParams.get("keyword");

	const queryParams: Record<string, string | number> = {};
	if (country) {
		queryParams.country = country;
	}
	if (keyword) {
		queryParams.keyword = keyword;
	}

	if (page !== 0) {
		queryParams.page = page;
	}
	return queryParams;
};

export default useQueryParams;
