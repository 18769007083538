import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as userListPath, title as userListTitle } from "./UserListRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import { useFindOne } from "../queries/resource.query";
import { UserOneFindType } from "../types/userTypes";
import UserOneEdit from "../components/UserOneEdit";

export const prefixPath = "/user";
export const path = `${prefixPath}/:id`;
const resource = "user";

const UserOneEditRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();
	const { id } = useParams();
	const user = useFindOne<UserOneFindType>(resource, id);

	if (!isLogin) {
		return <LoginRoute />;
	} else if (user.isLoading) {
		return <Fragment />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={userListTitle}
							preLink={userListPath}
							title={user.data!.display_name}
						/>
					}
				>
					<UserOneEdit />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UserOneEditRoute;
