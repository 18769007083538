import { useContext } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate } from "@reach/router";
import queryKeys from "./queryKeys";
import Utils from "../utils";
import { API_URL } from "../config";

import RefreshTokenContext from "../contexts/RefreshTokenContext";
import AccessTokenContext from "../contexts/AccessTokenContext";
import { path as HomeRoutePath } from "../routes/HomeRoute";

const prefix_url = "/auth";

export const useLoginMutation = () => {
	const navigate = useNavigate();
	const { setAccessToken } = useContext(AccessTokenContext);
	const { setRefreshToken } = useContext(RefreshTokenContext);

	return useMutation(
		queryKeys.AUTH_LOGIN,
		({ email, password }: { email: string; password: string }) =>
			axios.post(`${API_URL}${prefix_url}`, { email, password }),
		{
			async onSuccess({ data: { access_token, refresh_token } }) {
				setAccessToken(access_token);
				setRefreshToken(refresh_token);
				await navigate(HomeRoutePath, { replace: true });
			},
			onError({ response }) {
				Utils.alert(response?.data?.msg);
			},
		}
	);
};
