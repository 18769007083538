import { useContext } from "react";
import RefreshTokenContext from "../contexts/RefreshTokenContext";
import AccessTokenContext from "../contexts/AccessTokenContext";
import utils from "../utils";
import { createAjax, getAuthorizationHeader } from "./useAjax";

const useLogout = () => {
	const { setAccessToken } = useContext(AccessTokenContext);
	const { refreshToken, deleteRefreshToken } = useContext(RefreshTokenContext);

	return () => {
		const cleanUpLocalTokens = () => {
			deleteRefreshToken();
			setAccessToken(null);
			utils.redirectToLoginPage();
		};

		if (refreshToken) {
			const ajax = createAjax();
			ajax
				.post(
					"/auth/logout",
					{},
					{ headers: getAuthorizationHeader(refreshToken) }
				)
				.finally(cleanUpLocalTokens);
		} else {
			cleanUpLocalTokens();
		}
	};
};

export default useLogout;
