import querystring from "query-string";
import { StateMapType } from "../../hooks/useStateMap";
import ColumnType, { QueryParamsType } from "./columnType";

const getQueryParamsString = (
	columns: ColumnType[],
	defaultValue: StateMapType,
	queryParams?: QueryParamsType
): string => {
	if (!queryParams) return "";

	const keys = Object.keys(queryParams);
	if (keys.length === 0) return "";

	const obj: StateMapType = {};
	keys.forEach((key) => {
		const value = queryParams[key];
		const columnId = columns.find((e) => `$${e.id}` === value)!.id;
		obj[key] = defaultValue[columnId];
	});

	return `?${querystring.stringify(obj)}`;
};

export default getQueryParamsString;
