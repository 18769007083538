import React, { FC } from "react";
import styled from "styled-components";

const Container = styled.label`
	display: inline-flex;
`;

const Bar = styled.div`
	background-color: #3d6fc9;
	width: 4px;
	height: 1.25rem;
`;

const Text = styled.div`
	font-size: 0.875rem;
	color: #5c5a5a;
	margin-left: 6px;
`;

type Props = {
	className?: string;
	text: string;
};

const BarWithLabel: FC<Props> = ({ className, text }) => {
	return (
		<Container className={className}>
			<Bar />
			<Text>{text}</Text>
		</Container>
	);
};

export default BarWithLabel;
