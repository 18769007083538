export function getSection(name: string): any[] {
	return [
		{
			margin: [0, 15, 0, 0],
			table: {
				widths: [1, "*", "35%"],
				body: [
					[
						{
							text: "",
							fillColor: "#3D6FC9",
						},
						{
							text: name,
							color: "black",
						},
					],
				],
			},
			layout: "noBorders",
		},
		{
			canvas: [
				{
					type: "line",
					x1: 0,
					y1: 10,
					x2: 300,
					y2: 10,
					lineWidth: 1,
					color: "#707070",
					bold: true,
				},
			],
		},
	];
}
