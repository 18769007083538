import React, { FC } from "react";
import { IoMdAddCircle } from "react-icons/io";
import styled from "styled-components";

type Props = {
	onClick: () => void;
};

const TopBar = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;

	cursor: pointer;
	user-select: none;

	*:first-child {
		margin-right: 0.5rem;
	}
`;

const AddIcon = styled(IoMdAddCircle)`
	color: var(--green);
	width: 2rem;
	height: 2rem;
`;

const AddIconTextButton: FC<Props> = ({ onClick }) => {
	return (
		<TopBar onClick={onClick}>
			<AddIcon />
			<span>新增</span>
		</TopBar>
	);
};

export default AddIconTextButton;
