import React from "react";
import styled from "styled-components";
import { useIsFetching, useIsMutating } from "react-query";
import LinearProgress from "@material-ui/core/LinearProgress";
import Portal from "@material-ui/core/Portal";

const Container = styled.div<{ isFetching: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	width: 100%;
	opacity: ${(props) => (props.isFetching ? 1 : 0)};
`;

const AppProgressBar = () => {
	const fetchingCount = useIsFetching();
	const mutationCount = useIsMutating();

	return (
		<Portal>
			<Container isFetching={fetchingCount !== 0 || mutationCount !== 0}>
				<LinearProgress value={fetchingCount + mutationCount} />
			</Container>
		</Portal>
	);
};

export default AppProgressBar;
