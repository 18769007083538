import React, { FC, Fragment, useRef, useState } from "react";
import styled from "styled-components";
import ReactAvatarEditor, { Position } from "react-avatar-editor";
import Slider from "../Slider";
import Button from "../Button";

export type ImageType = {
	value: string;
	rotation: number;
	zoom: number;
	cut: string;
	positionX: number;
	positionY: number;
};

export type OnChangeProps = (obj: ImageType) => void;

type Props = {
	label: string;
	value: string;
	defaultRotation: number;
	defaultZoom: number;
	defaultPosition: Position;
	onChange: OnChangeProps;
};

const Container = styled.div`
	> *:not(:first-child) {
		margin-top: 30px;
	}
`;

const FieldLabel = styled.div`
	display: flex;
	align-items: center;

	> *:not(:first-child) {
		margin-left: 20px;
	}
`;

const RotationButtons = styled.div`
	> *:not(:first-child) {
		margin-left: 20px;
	}
`;

const AvatarEditor: FC<Props> = ({
	label,
	value,
	defaultRotation,
	defaultZoom,
	defaultPosition,
	onChange,
}) => {
	const isImageChangingRef = useRef(false);
	const [rotation, setRotation] = useState(defaultRotation);
	const [zoom, setZoom] = useState(defaultZoom);
	const [position, setPosition] = useState(defaultPosition);
	const ref = useRef(null);

	const getCurrentValue = (rotation: number) => {
		const editor = ref.current!;
		// @ts-ignore
		const cut = editor.getImageScaledToCanvas().toDataURL();

		isImageChangingRef.current = false;
		return {
			value,
			cut,
			zoom,
			rotation,
			positionX: position.x,
			positionY: position.y,
		};
	};

	const changeRotation = (toRotation: number) => {
		isImageChangingRef.current = true;

		setRotation(() => toRotation);
		const myInterval = window.setInterval(() => {
			if (!isImageChangingRef.current) {
				onChange(getCurrentValue(toRotation));
				clearInterval(myInterval);
			}
		}, 500);
	};

	if (value === "") {
		return <Fragment />;
	} else {
		return (
			<Container>
				<FieldLabel>
					<div>{label}</div>
					{/*@ts-ignore*/}
					<ReactAvatarEditor
						image={value}
						width={220}
						height={100}
						scale={zoom}
						rotate={rotation}
						ref={ref}
						crossOrigin="anonymous"
						position={position}
						onPositionChange={setPosition}
						onMouseUp={() => onChange(getCurrentValue(rotation))}
						onImageChange={() => {
							isImageChangingRef.current = false;
						}}
					/>
				</FieldLabel>
				<FieldLabel>
					<label style={{ minWidth: "fit-content" }}>縮放</label>
					<Slider
						min={0}
						max={2}
						step={0.01}
						value={zoom}
						onChange={(zoom) => {
							setZoom((_) => zoom);
						}}
						onChangeCommitted={() => onChange(getCurrentValue(rotation))}
					/>
				</FieldLabel>
				<FieldLabel>
					<label>旋轉</label>
					<RotationButtons>
						<Button onClick={() => changeRotation((rotation - 90) % 360)}>
							向左
						</Button>
						<Button onClick={() => changeRotation((rotation + 90) % 360)}>
							向右
						</Button>
					</RotationButtons>
				</FieldLabel>
				<FieldLabel>
					<label>重置</label>
					<Button
						onClick={() => {
							if (window.confirm("確定要重新上傳圖片？")) {
								onChange({
									value: "",
									rotation: 0,
									zoom: 1,
									cut: "",
									positionX: 0,
									positionY: 0,
								});
							}
						}}
					>
						重置
					</Button>
				</FieldLabel>
			</Container>
		);
	}
};

export default AvatarEditor;
