import React, { FC, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { AiOutlineSearch } from "react-icons/ai";
import EventListener from "react-event-listener";
import keycode from "keycode";
import useURLSearchParams from "../../../hooks/useURLSearchParams";
import useOnSearch from "../useOnSearch";

const Container = styled.div`
	position: relative;
	border: solid 0.5px var(--grey);
	border-radius: 6px;
`;

const Input = styled.input`
	width: 15ch;
	height: 100%;
	font: inherit;
	color: #434242;
	background-color: white;
	border-radius: 5px;
	border: 0;
	appearance: none;
	padding: 5px 5px 5px 25px;
	transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:focus {
		width: 24ch;
		outline: 0;
	}
`;

const Icon = styled(AiOutlineSearch)`
	position: absolute;
	height: 100%;

	color: #434242;
	top: 0;
	left: 5px;
`;

const SearchBar: FC = () => {
	useLocation();
	const urlSearchParams = useURLSearchParams();
	const keyword = urlSearchParams.get("keyword") || "";
	const onChange = useOnSearch("keyword");
	const inputEl = useRef(null);

	return (
		<Container>
			<Icon
				onClick={() => {
					// @ts-ignore
					inputEl.current.focus();
				}}
			/>
			{/*@ts-ignore*/}
			<EventListener
				target="window"
				onKeyDown={async (event) => {
					const isInputting = event.target === inputEl.current;

					if (isInputting && ["enter"].indexOf(keycode(event)) !== -1) {
						// @ts-ignore
						await onChange(event.target.value);
					}
				}}
			/>
			<Input type="search" defaultValue={keyword} ref={inputEl} />
		</Container>
	);
};

export default SearchBar;
