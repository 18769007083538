import React from "react";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import { RouteComponentProps } from "@reach/router";
import MainLayout from "../components/MainLayout";
import MajorList from "../components/MajorList";
import LayoutContent from "../components/MainLayout/LayoutContent";

export const path = "/";
export const title = "主頁";

const HomeRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent title={title} data-test-id={path}>
					<MajorList />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default HomeRoute;
