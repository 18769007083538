import React from "react";
import styled from "styled-components";
import { InputAdornment, TextField as _TextField } from "@material-ui/core";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";

const TextField = styled(_TextField)`
	width: 100%;
	input {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		-webkit-tap-highlight-color: transparent;
	}
`;

type Props = {
	id: string;
	testId: string;
	type: string;
	icon: React.FC;
	placeholder: string;
	value: string;
	onChange: StandardInputProps["onChange"];
};

const MyTextField = ({
	id,
	testId,
	type,
	icon: Icon,
	placeholder,
	value,
	onChange,
}: Props) => {
	return (
		<TextField
			id={id}
			value={value}
			onChange={onChange}
			inputProps={{
				type,
				placeholder,
				"data-test-id": testId,
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Icon />
					</InputAdornment>
				),
			}}
		/>
	);
};

export default MyTextField;
