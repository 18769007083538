import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { useQueryClient } from "react-query";
import {
	refreshOne,
	useFindOne,
	useUpdateColumnMutation,
} from "../../queries/resource.query";
import { MajorOneFindType } from "../../types/majorTypes";
import MajorOne from "../MajorOne";

const resource = "major";

const MajorOneEdit = (ignored: RouteComponentProps) => {
	const queryClient = useQueryClient();
	const { id } = useParams();
	const major = useFindOne<MajorOneFindType>(resource, id);
	const updateColumnMutation =
		useUpdateColumnMutation<MajorOneFindType>(resource);

	if (major.isLoading) {
		return <Fragment />;
	}

	return (
		<MajorOne
			isEdit
			errors={{}}
			defaultValue={major.data as MajorOneFindType}
			onChange={(field, value) => {
				updateColumnMutation.mutate(
					{
						id,
						column: field,
						value,
					},
					{
						async onSuccess(data) {
							await refreshOne<MajorOneFindType>(queryClient, resource, data);
						},
						async onError(data) {
							window.alert(data.response.data.msg);
						},
					}
				);
			}}
		/>
	);
};

export default MajorOneEdit;
