import { HeaderType } from "../KodenTable/KodenTable";

const useHeaders: () => HeaderType[] = () => {
	return [
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
		{
			id: "clone",
			label: "複製",
			align: "center",
		},
		{ id: "國家", label: "國家", align: "left" },
		{ id: "學校名稱", label: "學校名稱", align: "left" },
		{ id: "領域", label: "領域", align: "left" },
		{ id: "科系", label: "科系", align: "left" },
		{ id: "學位", label: "學位", align: "left" },
		{ id: "網頁", label: "網頁", align: "left" },
		{ id: "更新", label: "更新", align: "center" },
		{ id: "PDF", label: "PDF", align: "center" },
		{ id: "公開", label: "公開", align: "center" },
	];
};

export default useHeaders;
