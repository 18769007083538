import React, { FC } from "react";
import styled from "styled-components";
import CountrySelect from "../../CountrySelect";
import useURLSearchParams from "../../../hooks/useURLSearchParams";
import ClearAll from "../../ClearAll";
import useOnSearch from "../useOnSearch";

const Container = styled.div`
	display: flex;
	align-items: center;

	& > *:nth-child(2) {
		margin-left: 20px;
	}
`;

const QueryBoard: FC = () => {
	const searchParams = useURLSearchParams();
	const country = searchParams.get("country");
	const onChange = useOnSearch("country");

	return (
		<Container>
			<CountrySelect
				resource="university"
				value={country === null ? "" : country}
				onChange={onChange}
			/>
			<ClearAll />
		</Container>
	);
};

export default QueryBoard;
