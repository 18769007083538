import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { useQueryClient } from "react-query";
import {
	refreshOne,
	useFindOne,
	useUpdateColumnMutation,
	useUpdateColumnsMutation,
} from "../../queries/resource.query";
import { UniversityOneType } from "../../types/universityType";
import UniversityOne from "../UniversityOne";
import { ImageType } from "../AvatarEditor/AvatarEditor";
import { IMG_PREFIX_URL } from "../../config";

const resource = "university";

const normalizeImage: (image: ImageType) => ImageType = (image) => {
	const imageUrl = `${IMG_PREFIX_URL}/`;
	image.value = image.value.replaceAll(imageUrl, "");
	image.cut = image.cut.replaceAll(imageUrl, "");

	return image;
};

const UniversityOneEdit = (ignored: RouteComponentProps) => {
	const queryClient = useQueryClient();
	const { id } = useParams();
	const record = useFindOne<UniversityOneType>(resource, id);
	const updateColumnMutation =
		useUpdateColumnMutation<UniversityOneType>(resource);
	const updatePlaceMutation = useUpdateColumnsMutation<UniversityOneType>(
		resource,
		"place"
	);

	const updateImageMutation = useUpdateColumnsMutation<UniversityOneType>(
		resource,
		"image"
	);

	if (record.isLoading) {
		return <Fragment />;
	}

	return (
		<UniversityOne
			isEdit
			errors={{}}
			defaultValue={record.data as UniversityOneType}
			onPlaceChange={(values) => {
				updatePlaceMutation.mutate(
					{ id, values },
					{
						async onSuccess(data) {
							await refreshOne<UniversityOneType>(queryClient, resource, data);
						},
						async onError(data) {
							window.alert(data.response.data.msg);
						},
					}
				);
			}}
			onImageChange={(image) => {
				const values = normalizeImage(image);
				updateImageMutation.mutate(
					{ id, values },
					{
						async onSuccess(data) {
							await refreshOne<UniversityOneType>(queryClient, resource, data);
						},
						async onError(data) {
							window.alert(data.response.data.msg);
						},
					}
				);
			}}
			onChange={(column, value) => {
				updateColumnMutation.mutate(
					{
						id,
						column,
						value,
					},
					{
						async onSuccess(data) {
							await refreshOne<UniversityOneType>(queryClient, resource, data);
						},
						async onError(data) {
							window.alert(data.response.data.msg);
						},
					}
				);
			}}
		/>
	);
};

export default UniversityOneEdit;
