import React, { FC } from "react";
import TextField, { Props as TextFieldProps } from "../TextField";

export interface Props
	extends Omit<
		TextFieldProps,
		"value" | "defaultValue" | "onChange" | "placeholder"
	> {
	min: number;
	max: number;
	step: number;
	value?: number;
	defaultValue?: number;
	onChange: (v: number) => void;
}

const NumberField: FC<Props> = ({
	min,
	max,
	step,
	value,
	defaultValue,
	onChange,
	...props
}) => {
	const inputProps = {
		inputProps: { type: "number", min, max, step },
	};

	const textFieldProps: TextFieldProps = {
		...props,
		placeholder: "",
		inputProps,
		onChange(value) {
			const number = parseFloat(value);
			if (min > number || number > max) {
				onChange(min);
			} else {
				onChange(number);
			}
		},
	};

	if (value !== undefined) {
		textFieldProps.value = value.toString();
	}
	if (defaultValue !== undefined) {
		textFieldProps.defaultValue = defaultValue.toString();
	}

	return <TextField {...textFieldProps} />;
};

export default NumberField;
