import React from "react";
import { CellType } from "../KodenTable/KodenTable";
import BinIcon from "../Binicon";
import { useRemoveMutation } from "../../queries/resource.query";
import { UserRowType } from "../../types/userTypes";
import UserAvatar from "../UserAvatar";
import Button from "../Button";

const resource = "user";

type OnChangePasswordClick = (id: string) => void;
type UseCellsType = (
	onChangePasswordClick: OnChangePasswordClick
) => CellType[];

const useCells: UseCellsType = (onChangePasswordClick) => {
	const removeMutation = useRemoveMutation(resource);

	return [
		{
			id: "delete",
			align: "center",
			style: {
				width: 65,
			},
			isEllipsis: false,
			component: (user: UserRowType) => {
				return (
					<BinIcon
						disabled={removeMutation.isLoading}
						onClick={(e) => {
							e.stopPropagation();

							if (window.confirm(`確定要刪除${user.display_name}?`)) {
								removeMutation.mutate(
									{ id: user.id },
									{
										onSuccess() {
											window.location.reload();
										},
									}
								);
							}
						}}
					/>
				);
			},
		},
		{
			id: "avatar",
			align: "center",
			isEllipsis: true,
			style: {
				width: 80,
			},
			component: ({ display_name, color }: UserRowType) => {
				return <UserAvatar value={display_name} color={color} />;
			},
		},
		{
			id: "display_name",
			align: "left",
			isEllipsis: true,
			style: {
				maxWidth: 80,
			},
			component: ({ display_name }: UserRowType) => {
				return display_name;
			},
		},
		{
			id: "email",
			align: "left",
			style: {
				maxWidth: 100,
			},
			isEllipsis: true,
			component: ({ email }: UserRowType) => {
				return email;
			},
		},
		{
			id: "changePassword",
			align: "left",
			isEllipsis: true,
			style: {
				minWidth: 200,
			},
			component: ({ id }: UserRowType) => {
				return (
					<Button
						onClick={(event) => {
							event.stopPropagation();
							onChangePasswordClick(id);
						}}
					>
						更改密碼
					</Button>
				);
			},
		},
	];
};

export default useCells;
