import { MajorOnePdfType } from "../../../types/majorTypes";

export function getIELTSContent(major: MajorOnePdfType): any {
	const scores = ["", "", "", "", "", "", "", ""];
	let i = 0;
	if (major.ielts_listening > 0) {
		scores[i++] = `Listening`;
		scores[i++] = major.ielts_listening.toString();
	}
	if (major.ielts_speaking > 0) {
		scores[i++] = `Speaking`;
		scores[i++] = major.ielts_speaking.toString();
	}
	if (major.ielts_listening > 0) {
		scores[i++] = `Listening`;
		scores[i++] = major.ielts_listening.toString();
	}
	if (major.ielts_writing > 0) {
		scores[i++] = `Writing`;
		scores[i++] = major.ielts_writing.toString();
	}

	return {
		layout: "noBorders",
		style: "content",
		table: {
			headerRows: 1,
			widths: [
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
			],
			body: [
				[
					{ text: "IELTS", bold: true, margin: [0, 0, 0, 10] },
					`Total ${major.ielts_overall}`,
					"",
					"",
					"",
					"",
					"",
					"",
				],
				scores,
			],
			layout: {
				paddingLeft: function () {
					return 0;
				},
				paddingRight: function () {
					return 0;
				},
				paddingTop: function () {
					return 200;
				},
				paddingBottom: function () {
					return 0;
				},
			},
		},
	};
}
