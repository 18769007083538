import React, { FC } from "react";
import KodenSelect from "../KodenSelect";

type Props = {
	value: string;
	onChange: (s: string) => void;
};

const DiffRatioKodenSelect: FC<Props> = ({ value = "", onChange }) => {
	let options = [
		{ id: "", value: "", label: "更新" },
		{ id: "true", value: "true", label: "是" },
		{ id: "false", value: "false", label: "否" },
	];
	return <KodenSelect options={options} value={value} onChange={onChange} />;
};

export default DiffRatioKodenSelect;
