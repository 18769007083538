import { HeaderType } from "../KodenTable/KodenTable";

const useHeaders: () => HeaderType[] = () => {
	return [
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
		{
			id: "avatar",
			label: "",
			align: "center",
		},
		{ id: "display_name", label: "名稱", align: "left" },
		{ id: "header_email", label: "Email", align: "left" },
		{ id: "change_password", label: "", align: "left" },
	];
};

export default useHeaders;
