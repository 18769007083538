import React, { FC } from "react";
import querystring from "query-string";
import styled from "styled-components";
import { useLocation, useNavigate } from "@reach/router";
import CountrySelect from "../CountrySelect";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import ClearAll from "../ClearAll";
import UniversityKodenSelect from "../UniversityKodenSelect";
import FieldOfStudyKodenSelect from "../FieldOfStudyKodenSelect";
import MajorNameKodenSelect from "../MajorNameKodenSelect";
import DiffRatioKodenSelect from "../DiffRatioKodenSelect";

type QueryFieldType =
	| "country"
	| "university"
	| "field_of_study"
	| "name"
	| "diff_ratio";

const Container = styled.div`
	display: flex;
	align-items: center;

	& > *:not(:first-child) {
		margin-left: 20px;
	}
`;

const MajorQueryBoard: FC = () => {
	const searchParams = useURLSearchParams();
	const country = searchParams.get("country") || "";
	const university = searchParams.get("university") || "";
	const field_of_study = searchParams.get("field_of_study") || "";
	const name = searchParams.get("name") || "";
	const diffRatio = searchParams.get("diff_ratio") || "";

	const navigate = useNavigate();
	const location = useLocation();

	const onChange = async (field: QueryFieldType, value: string) => {
		const query = Object.fromEntries(searchParams);
		if (value !== "") {
			query[field] = value;
		} else {
			delete query[field];
		}
		await navigate(`${location.pathname}?${querystring.stringify(query)}`);
	};

	return (
		<Container>
			<CountrySelect
				resource="major"
				value={country}
				onChange={async (value) => {
					await onChange("country", value);
				}}
			/>
			<UniversityKodenSelect
				value={university}
				onChange={async (value) => {
					await onChange("university", value);
				}}
			/>
			<FieldOfStudyKodenSelect
				value={field_of_study}
				onChange={async (value) => {
					await onChange("field_of_study", value);
				}}
			/>
			<MajorNameKodenSelect
				value={name}
				onChange={async (value) => {
					await onChange("name", value);
				}}
			/>
			<DiffRatioKodenSelect
				value={diffRatio}
				onChange={async (value) => {
					await onChange("diff_ratio", value);
				}}
			/>
			<ClearAll />
		</Container>
	);
};

export default MajorQueryBoard;
