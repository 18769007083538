import React, { FC, useState } from "react";
import { GoLocation as LocationIcon } from "react-icons/go";
import ChooseLocationDialog, { PlaceType } from "./ChoosePlaceDialog";
import { FormHelperText } from "@material-ui/core";

export type OnChangeProps = (obj: PlaceType) => void;

type Props = {
	id: string;
	error: string;
	defaultValue: PlaceType;
	onChange: OnChangeProps;
};

const PlaceField: FC<Props> = ({ id, defaultValue, onChange, error }) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<label style={{ marginRight: 20, width: 55 }}>地點：</label>
				{defaultValue.place_id !== "" && (
					<>
						{defaultValue.formatted_address}
						{defaultValue.long_country}({defaultValue.short_country})
						{defaultValue.long_city}({defaultValue.short_city})
					</>
				)}
				<LocationIcon
					style={{ fontSize: 22, cursor: "pointer" }}
					onClick={() => setOpen(true)}
				/>
				<ChooseLocationDialog
					id={`${id}_dialog`}
					open={open}
					defaultKeyword=""
					onClose={() => {
						setOpen(false);
					}}
					onConfirm={(place) => {
						setOpen(false);
						onChange(place);
					}}
				/>
			</div>
			<FormHelperText error={error !== ""}>{error}</FormHelperText>
		</div>
	);
};

export default PlaceField;
