import React, { useState } from "react";
import { Router } from "@reach/router";
import "@fontsource/roboto";
import { CookieAttributes } from "js-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import AppTheme from "../../themes/AppTheme";
import AccessTokenContext from "../../contexts/AccessTokenContext";
import RefreshTokenContext from "../../contexts/RefreshTokenContext";
import routes from "../../routes";
import useCookie from "../../hooks/useCookie";
import { REFRESH_TOKEN_KEY } from "../../config";
import Utils from "../../utils";
import AppProgressBar from "../AppProgressBar";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchOnMount: true,
		},
	},
});

function App() {
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const {
		value: refreshToken,
		update,
		deleteCookie: deleteRefreshToken,
	} = useCookie(REFRESH_TOKEN_KEY);

	const setRefreshToken = (refreshToken: string) => {
		const options: CookieAttributes = {
			expires: 7,
		};
		if (Utils.isHttps()) {
			options.secure = true;
		}
		update(refreshToken, options);
	};

	return (
		<AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
			<RefreshTokenContext.Provider
				value={{ refreshToken, setRefreshToken, deleteRefreshToken }}
			>
				<QueryClientProvider client={queryClient}>
					<AppProgressBar />
					<AppTheme>
						{/*@ts-ignore*/}
						<Router>
							{routes.map(({ id, path, component: Component }) => {
								return <Component key={id} path={path} />;
							})}
						</Router>
					</AppTheme>
				</QueryClientProvider>
			</RefreshTokenContext.Provider>
		</AccessTokenContext.Provider>
	);
}

export default App;
