import React, { FC } from "react";
import SelectField, { Props as SelectFieldProps } from "../SelectField";

export type FeeUnitValueType = "" | "YEAR" | "SEMESTER" | "PROGRAM";
export type FeeUnitLabelType = "" | "年" | "學期" | "學程";

type Option = {
	id: FeeUnitValueType;
	value: FeeUnitValueType;
	label: FeeUnitLabelType;
};

const feeUnitOptions: Option[] = [
	{
		id: "YEAR",
		value: "YEAR",
		label: "年",
	},
	{
		id: "SEMESTER",
		value: "SEMESTER",
		label: "學期",
	},
	{
		id: "PROGRAM",
		value: "PROGRAM",
		label: "學程",
	},
];

export interface Props
	extends Omit<
		SelectFieldProps,
		"value" | "defaultValue" | "onChange" | "defaultLabel" | "options"
	> {
	value?: FeeUnitValueType;
	defaultValue?: FeeUnitValueType;
	onChange: (currency: FeeUnitValueType) => void;
}

const FeeUnitSelect: FC<Props> = ({
	id,
	disabled,
	error,
	value,
	defaultValue,
	onChange,
	className,
}) => {
	const selectFieldProps: SelectFieldProps = {
		id,
		className,
		disabled,
		options: feeUnitOptions,
		defaultLabel: "單位",
		value,
		error,
		onChange(e) {
			onChange(e as FeeUnitValueType);
		},
	};

	if (value !== undefined) {
		selectFieldProps.value = value;
	}
	if (defaultValue !== undefined) {
		selectFieldProps.defaultValue = defaultValue;
	}

	return <SelectField {...selectFieldProps} />;
};

export default FeeUnitSelect;
