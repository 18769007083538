import React, { FC, useState } from "react";
import Header from "./Header";
import useIsWeb from "../../hooks/useIsWeb";
import Drawer from "./Drawer";
import Sidebar from "./Sidebar";
import styled, { css } from "styled-components";

type Type = FC<{
	children: any;
}>;

const ComponentBox = styled.div<{ isWeb: boolean }>`
	display: flex;
	justify-content: center;
	margin-top: auto;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - var(--header-height));
	overflow-x: auto;

	${(props) =>
		props.isWeb &&
		css`
			margin-left: var(--sidebar-width);
			width: calc(100% - var(--sidebar-width));
		`}
`;

const MainLayout: Type = ({ children }) => {
	const [open, setOpen] = useState(false);
	const isWeb = useIsWeb();

	return (
		<div data-test-id={`main-layout-open(${open})`}>
			<Header onHamburgerClick={() => setOpen(!open)} />
			{!isWeb && <Drawer open={open} onClose={() => setOpen(false)} />}
			{isWeb && <Sidebar open={open} />}
			<ComponentBox isWeb={isWeb}>{children}</ComponentBox>
		</div>
	);
};

export default MainLayout;
