import React, { FC } from "react";
import styled from "styled-components";
import RoundedButton from "./RoundedButton";

const Container = styled.label`
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
`;

const Label = styled.span`
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	margin-right: 1.875rem;
`;

type Props = {
	checked: boolean;
	label: string;
	onChange: () => void;
};

const RadioButton: FC<Props> = ({ checked, label, onChange }) => {
	return (
		<Container>
			<Label>{label}</Label>
			<RoundedButton checked={checked} onChange={onChange} />
		</Container>
	);
};

export default RadioButton;
