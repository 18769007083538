import { useFindAll } from "../../queries/resource.query";
import useSubmitMajorMutation from "../MajorOne/useSubmitMajorMutation";
import { MajorErrorsType } from "../../types/majorTypes";

type Type = (errors: MajorErrorsType) => boolean;

const useButtonDisabled: Type = (errors) => {
	const addMutation = useSubmitMajorMutation();
	const university = useFindAll("university");
	const type_of_school = useFindAll("type_of_school");
	const level = useFindAll("level");
	const field_of_study = useFindAll("field_of_study");
	const qualification = useFindAll("qualification");
	const pace = useFindAll("pace");
	const duration = useFindAll("duration");
	const submit = useSubmitMajorMutation();
	const hasError = !!Object.values(errors).find((e) => e !== "");

	return (
		addMutation.isLoading ||
		hasError ||
		university.isLoading ||
		type_of_school.isLoading ||
		level.isLoading ||
		field_of_study.isLoading ||
		qualification.isLoading ||
		pace.isLoading ||
		duration.isLoading ||
		submit.isLoading
	);
};

export default useButtonDisabled;
