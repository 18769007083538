import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import utils from "../../../utils";
import { MajorOnePdfType } from "../../../types/majorTypes";
import useAjax from "../../../hooks/useAjax";
import { getRankingContent } from "./getRankingContent";
import { getFeeContent } from "./getFeeContent";
import { getLocationInformation } from "./getLocationInformation";
import { getSection } from "./getSection";
import { getIELTSContent } from "./getIELTSContent";
import { getTOEFLContent } from "./getTOEFLContent";
import { getEntryRequirementsContent } from "./getEntryRequirementsContent";

const useGetMajorOneForPdf: () => (id: string) => Promise<MajorOnePdfType> =
	() => {
		const ajax = useAjax();

		return (id: string) => {
			return ajax.get(`/major/${id}/pdf`).then((e) => e.data);
		};
	};

export const useOnPdfClick = () => {
	const getPublicMajorOne = useGetMajorOneForPdf();

	return async (id: string) => {
		const major = await getPublicMajorOne(id);
		const universityLogo = utils.getAbsoluteImageUrl(major.logo_cut);

		let content = [
			{
				image: "logo",
				width: 150,
			},
			{
				text: major.name,
				style: "header1",
			},
			{
				text: major.university,
				style: "header2",
			},
			{
				columns: [
					{
						text: major.qualification,
						width: "auto",
						style: "circle",
					},
					{
						text: major.type_of_school,
						width: "auto",
						style: "circle",
					},
					{
						text: major.level,
						width: "auto",
						style: "circle",
					},
				],
				columnGap: 10,
				style: "header2",
			},
		];
		content = content.concat(getSection("FEATURES"));
		content.push({
			text: major.features,
			style: "content",
		});
		content = content.concat(getSection("LOCATION & INFORMATION"));
		content.push({
			// @ts-ignore
			ul: getLocationInformation(major),
			style: "content",
		});
		content = content.concat(getRankingContent(major));
		content = content.concat(getFeeContent(major));
		content = content.concat(getSection("LANGUAGE REQUIREMENT"));
		content.push(getIELTSContent(major));
		content.push(getTOEFLContent(major));
		if (major.language_website !== "") {
			content.push({
				// @ts-ignore
				text: [
					// @ts-ignore
					`Pre-sessional English course: `,
					// @ts-ignore
					{
						text: major.language_website,
						link: major.language_website,
						color: "#3D6FC9",
						bold: true,
					},
				],
				style: "content",
			});
		}
		content = content.concat(getSection("ENTRY REQUIREMENTS"));
		content.push(getEntryRequirementsContent(major));

		(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
		(pdfMake as any).fonts = {
			ChineseFont: {
				normal:
					"https://storage.googleapis.com/astar-data/fonts/Noto_Sans_TC/NotoSansTC-Regular.otf",
				bold: "https://storage.googleapis.com/astar-data/fonts/Noto_Sans_TC/NotoSansTC-Bold.otf",
				italics:
					"https://storage.googleapis.com/astar-data/fonts/Noto_Sans_TC/NotoSansTC-Black.otf",
				bolditalics:
					"https://storage.googleapis.com/astar-data/fonts/Noto_Sans_TC/NotoSansTC-Black.otf",
			},
		};
		pdfMake
			.createPdf({
				content,
				defaultStyle: {
					font: "ChineseFont",
				},
				styles: {
					header1: {
						fontSize: 18,
						bold: true,
						margin: [0, 16, 0, 0],
					},
					header2: {
						fontSize: 18,
						bold: true,
						alignment: "left",
						margin: [0, 16, 0, 0],
					},
					circle: {
						alignment: "left",
						fontSize: 12,
					},
					section: {
						margin: [0, 16, 16, 0],
					},
					content: {
						margin: [8, 8, 0, 0],
					},
				},
				images: {
					logo: universityLogo,
				},
			})
			.download(major.name);
	};
};
