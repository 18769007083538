import _ from "lodash";
import React, { FC } from "react";
import Panel from "../Panel";
import BarWithLabelForField from "../BarWithLabelForField";
import CurrencySelect from "../CurrencySelect";
import FeeUnitSelect from "../FeeUnitSelect";
import getError from "./getError";
import { Props } from "./types";
import LeftAndRight from "./LeftAndRight";
import { Grid } from "@material-ui/core";
import GridItem from "./GridItem";
import FlexBox from "../FlexBox";
import styled from "styled-components";
import { MyNumberField } from "./index";

const MyCurrencySelect = styled(CurrencySelect)`
	width: 100px;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
`;

const MyFeeUnitSelect = styled(FeeUnitSelect)`
	width: 100px;
`;

const MajorOneFeePanel: FC<Props> = ({
	isEdit,
	errors,
	defaultValue: major,
	onChange,
}) => {
	const debounceValue = isEdit ? 500 : 0;

	return (
		<Panel title="費用">
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="學費">
						<LeftAndRight>
							<MyNumberField
								min={0}
								max={10000000}
								step={1}
								id="fee-from"
								disabled={false}
								error={getError(errors, "fee_from")}
								readOnly={false}
								autoFocus={false}
								defaultValue={major.fee_from}
								onChange={_.debounce(
									(value) => onChange("fee_from", value),
									debounceValue
								)}
							/>
							<FlexBox style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
								~
							</FlexBox>
							<MyNumberField
								min={0}
								max={10000000}
								step={1}
								id="fee-to"
								disabled={false}
								error={getError(errors, "fee_to")}
								readOnly={false}
								autoFocus={false}
								defaultValue={major.fee_to}
								onChange={_.debounce(
									(value) => onChange("fee_to", value),
									debounceValue
								)}
							/>
							<MyCurrencySelect
								id="fee_currency"
								disabled={false}
								error={getError(errors, "fee_currency")}
								value={major.fee_currency}
								onChange={(value) => onChange("fee_currency", value)}
							/>
							<MyFeeUnitSelect
								id="fee_unit"
								disabled={false}
								value={major.fee_unit}
								error={getError(errors, "fee_unit")}
								onChange={(value) => onChange("fee_unit", value)}
							/>
						</LeftAndRight>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="申請費">
						<LeftAndRight>
							<MyNumberField
								min={0}
								max={10000000}
								step={1}
								id="app_fee"
								disabled={false}
								error={getError(errors, "app_fee")}
								readOnly={false}
								autoFocus={false}
								defaultValue={major.app_fee}
								onChange={_.debounce(
									(value) => onChange("app_fee", value),
									debounceValue
								)}
							/>
							<MyCurrencySelect
								id="app_currency"
								disabled={false}
								error={getError(errors, "app_currency")}
								value={major.app_currency}
								onChange={(value) => onChange("app_currency", value)}
							/>
						</LeftAndRight>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
		</Panel>
	);
};

export default MajorOneFeePanel;
