import React from "react";
import { Link } from "@reach/router";
import WebSvg from "./web.svg";
import PdfSvg from "./pdf.svg";
import { CellType } from "../KodenTable/KodenTable";
import BinIcon from "../Binicon";
import { FaRegClone } from "react-icons/fa";

import {
	useCloneMutation,
	useRemoveMutation,
	useUpdateColumnMutation,
} from "../../queries/resource.query";
import { MajorRowType } from "../../types/majorTypes";
import IOSSwitch from "../IOSSwitch";
import { prefixPath as MajorOneEditScreenshotRoute } from "../../routes/MajorOneEditScreenshotRoute";
import Icon from "../Icon";
import FlexBox from "../FlexBox";
import useFindMajorAll from "./useFindMajorAll";
import { useOnPdfClick } from "./pdf/useOnPdfClick";

const mutationResource = "major";

function useCells(): CellType[] {
	const findAll = useFindMajorAll();
	const removeMutation = useRemoveMutation(mutationResource);
	const updateColumnMutation = useUpdateColumnMutation(mutationResource);
	const cloneMutation = useCloneMutation(mutationResource);
	const onPdfClick = useOnPdfClick();

	return [
		{
			id: "delete",
			align: "center",
			style: {
				minWidth: 65,
			},
			component: (major: MajorRowType) => {
				return (
					<BinIcon
						disabled={removeMutation.isLoading}
						onClick={(e) => {
							e.stopPropagation();

							if (window.confirm(`確定要刪除${major.name}?`)) {
								removeMutation.mutate(
									{ id: major.id },
									{
										async onSuccess() {
											await findAll.refetch();
										},
									}
								);
							}
						}}
					/>
				);
			},
		},
		{
			id: "clone",
			align: "center",
			style: {
				minWidth: 65,
			},
			component: (major: MajorRowType) => {
				return (
					<div
						onClick={(e) => {
							e.stopPropagation();
							if (window.confirm(`確定要複製${major.name}?`)) {
								cloneMutation.mutate(
									{ id: major.id },
									{
										async onSuccess() {
											await findAll.refetch();
										},
										async onError(err) {
											const errors: Record<string, any> = (err.response.data
												.errors || {}) as unknown as Record<string, any>;
											alert(Object.values(errors).join("\n"));
										},
									}
								);
							}
						}}
					>
						<FaRegClone />
					</div>
				);
			},
		},
		{
			id: "country",
			align: "left",
			isEllipsis: true,
			component: (major: MajorRowType) => {
				return major.country;
			},
		},
		{
			id: "university",
			align: "left",
			isEllipsis: true,
			style: {
				maxWidth: 200,
			},
			component: (major: MajorRowType) => {
				return major.university;
			},
		},
		{
			id: "field_of_study",
			align: "left",
			isEllipsis: true,
			component: (major: MajorRowType) => {
				return (
					<div>
						{major.field_of_study_names.map((e) => {
							return <div key={e}>{e}</div>;
						})}
					</div>
				);
			},
		},
		{
			id: "name",
			align: "left",
			style: {
				width: "30ch",
				minWidth: "30ch",
			},
			component: ({ name }: MajorRowType) => {
				return name;
			},
		},
		{
			id: "qualification",
			align: "left",
			isEllipsis: true,
			component: (major: MajorRowType) => {
				return major.qualification;
			},
		},
		{
			id: "website",
			align: "left",
			isEllipsis: true,
			component: ({ website }: MajorRowType) => {
				return (
					<a rel="noreferrer" target="_blank" href={website} title={website}>
						<Icon src={WebSvg} alt={website} />
					</a>
				);
			},
		},
		{
			id: "is_updated",
			align: "center",
			isEllipsis: true,
			component: ({ id, diff_ratio }: MajorRowType) => {
				return (
					<FlexBox
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Link
							to={`${MajorOneEditScreenshotRoute}/${id}/screenshot`}
							style={{
								borderRadius: 50,
								width: 20,
								height: 20,
								backgroundColor: diff_ratio === 0 ? "#94D06E" : "#E58080",
							}}
						/>
					</FlexBox>
				);
			},
		},
		{
			id: "pdf",
			align: "center",
			component: ({ id }: MajorRowType) => {
				return (
					<div
						title=""
						onClick={async (e) => {
							e.stopPropagation();
							await onPdfClick(id);
						}}
					>
						<Icon src={PdfSvg} alt="" />
					</div>
				);
			},
		},
		{
			id: "is_public",
			align: "center",
			isEllipsis: true,
			component: (major: MajorRowType) => {
				return (
					// @ts-ignore
					<IOSSwitch
						id={`is_public_${major.id}`}
						checked={major.is_public}
						onClick={(event: any) => {
							event.stopPropagation();
						}}
						onChange={(event: any) => {
							updateColumnMutation.mutate(
								{
									id: major.id,
									column: "is_public",
									value: event.target.checked,
								},
								{
									async onSuccess() {
										await findAll.refetch();
									},
								}
							);
						}}
					/>
				);
			},
		},
	];
}

export default useCells;
