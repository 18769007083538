import React, { FC, Fragment } from "react";
import { FormHelperText } from "@material-ui/core";
import TextField from "../TextField";
import RemoteSelectField from "../RemoteSelectField";
import getQueryParamsString from "./getQueryParamsString";
import InputType from "./inputTypes";
import ColumnType from "./columnType";
import { SetStateMapType, StateMapType } from "../../hooks/useStateMap";
import emptyChildrenState from "./emptyChildrenState";
import { ErrorsType } from "./getErrors";

type Props = {
	columns: ColumnType[];
	disabled: boolean;
	dirty: boolean;
	errors: ErrorsType;
	errorMsg: string;
	stateMap: StateMapType;
	setStateMap: SetStateMapType;
};

const DialogColumns: FC<Props> = ({
	columns,
	disabled,
	dirty,
	errors,
	errorMsg,
	stateMap,
	setStateMap,
}) => {
	return (
		<>
			{columns.map((column) => {
				if (column.isVisible) {
					const {
						id,
						inputType,
						children,
						label,
						autoFocus,
						placeholder,
						url,
						queryParams,
					} = column;
					const value = stateMap[id];
					const error = !dirty ? "" : errors[id];

					switch (inputType) {
						case InputType.remoteList:
							const queryUrl = `${url!}${getQueryParamsString(
								columns,
								stateMap,
								queryParams
							)}`;

							return (
								<RemoteSelectField
									key={id}
									id={id}
									url={queryUrl}
									defaultLabel={label}
									value={value as string}
									error={error}
									disabled={disabled}
									onChange={(value) => {
										setStateMap((map) => {
											const toMap = { ...map, [id]: value };
											return emptyChildrenState(toMap, id, columns, children);
										});
									}}
								/>
							);
						case InputType.text:
						default:
							return (
								<TextField
									key={id}
									id={id}
									readOnly={false}
									label={label}
									autoFocus={autoFocus}
									placeholder={placeholder}
									disabled={disabled}
									value={value as string}
									error={error}
									onChange={(value) => {
										setStateMap((map) => {
											const toMap = { ...map, [id]: value };
											return emptyChildrenState(toMap, id, columns, children);
										});
									}}
								/>
							);
					}
				} else {
					return <Fragment key={column.id} />;
				}
			})}
			<FormHelperText error={errorMsg !== ""}>{errorMsg}</FormHelperText>
		</>
	);
};

export default DialogColumns;
