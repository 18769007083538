import React from "react";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import { RouteComponentProps } from "@reach/router";
import MainLayout from "../components/MainLayout";
import CategoryList from "../components/CategoryList";
import LayoutContent from "../components/MainLayout/LayoutContent";

export const path = "/category";
export const title = "分類管理";

const CategoryListRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent title={title} data-test-id={path}>
					<CategoryList />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default CategoryListRoute;
