import React, { FC } from "react";
import { useNavigate } from "@reach/router";
import styled from "styled-components";
import { path as homePath } from "../../routes/HomeRoute";
import { path } from "../../routes/MajorOneAddRoute";
import AddIconTextButton from "../AddIconTextButton";
import KodenTable from "../KodenTable";
import useCells from "./useCells";
import useHeaders from "./useHeaders";
import querystring from "query-string";
import usePage from "../../hooks/usePage";
import { prefixPath } from "../../routes/MajorOneEditRoute";
import MajorQueryBoard from "../MajorQueryBoard";
import useResource from "./useResource";
import SearchBar from "../UniversityList/SearchBar";

const Container = styled.div`
	& > * {
		margin-bottom: 1rem;
	}
`;

export const rowsPerPage = 20;

const MajorList: FC = () => {
	const resource = useResource();
	const navigate = useNavigate();
	const headers = useHeaders();
	const cells = useCells();
	const page = usePage();

	return (
		<Container>
			<MajorQueryBoard />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<AddIconTextButton
					onClick={async () => {
						await navigate(path);
					}}
				/>
				<SearchBar />
			</div>
			<KodenTable
				headers={headers}
				cells={cells}
				resource={resource}
				page={page}
				rowsPerPage={rowsPerPage}
				onRowClick={async (row) => {
					await navigate(`${prefixPath}/${row.id}`);
				}}
				onPageChange={async (page) => {
					if (page === 0) {
						await navigate(homePath);
					} else {
						await navigate(
							`${homePath}?${querystring.stringify({
								page,
							})}`
						);
					}
				}}
			/>
		</Container>
	);
};

export default MajorList;
