import _ from "lodash";
import React, { FC, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import FormControl from "@material-ui/core/FormControl/FormControl";
import PasswordField from "../PasswordField";

function isPasswordValid(password: string) {
	return !(_.isEmpty(password) || password.trim().length < 8);
}

function getPasswordError(password: string) {
	if (_.isEmpty(password)) {
		return "請輸入密碼";
	} else if (!isPasswordValid(password)) {
		return "密碼長度必須大於8";
	}
}

function getError(pw_1: string, pw_2: string) {
	if (!_.isEqual(pw_1, pw_2)) {
		return "兩次密碼輸入不正確";
	} else if (!isPasswordValid(pw_1)) {
		return getPasswordError(pw_1);
	}
	return "";
}

function isDisabled(pw1 = "", pw2 = "") {
	if (_.isEmpty(pw1) || _.isEmpty(pw2)) {
		return true;
	} else if (!_.isEqual(pw1, pw2)) {
		return true;
	} else if (!_.isEmpty(getError(pw1, pw2))) {
		return true;
	}
	return false;
}

type Props = {
	open: boolean;
	onSubmit: (pw1: string, pw2: string) => void;
	onClose: () => void;
};

const ChangePasswordDialog: FC<Props> = ({ open, onSubmit, onClose }) => {
	const [pw1, setPw1] = useState("");
	const [pw2, setPw2] = useState("");

	return (
		<div>
			<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">更改密碼</DialogTitle>
				<DialogContent>
					<form style={{ display: "flex", flexDirection: "column" }}>
						<input hidden type="text" name="username" autoComplete="username" />
						<PasswordField
							id="pw1"
							label="輸入新的密碼"
							value={pw1}
							onChange={(value) => setPw1(value)}
							autoFocus
							disabled={false}
							error={""}
							readOnly={false}
						/>
						<PasswordField
							id="pw2"
							label="重新輸入新密碼"
							value={pw2}
							onChange={(value) => setPw2(value)}
							autoFocus={false}
							disabled={false}
							error={""}
							readOnly={false}
						/>
						<FormControl error>
							<FormHelperText id="component-error-text">
								{getError(pw1, pw2)}
							</FormHelperText>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={onClose}>
						取消
					</Button>
					<Button
						color="primary"
						disabled={isDisabled(pw1, pw2)}
						onClick={() => onSubmit(pw1, pw2)}
					>
						確定
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ChangePasswordDialog;
