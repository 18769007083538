import React, { FC } from "react";
import styled from "styled-components";
import Icon from "../Icon";
import FlexBox from "../FlexBox";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 1rem;

	&:hover {
		background-color: #e9e9e9;
	}
`;

const LeftAndRight = styled.div`
	display: grid;
	grid-template-columns: 40px 1fr;
	width: 100%;
`;

const iconStyle = {
	width: 26,
	height: 26,
	color: "#818080",
};

type Props = {
	testId: string;
	open: boolean;
	icon: string;
	txt: string;
	onClick: () => void;
};

const IconAndText: FC<Props> = ({ testId, open, icon, txt, onClick }) => {
	return (
		<Container onClick={onClick} title={txt} data-test-id={testId}>
			{!open ? (
				<Icon src={icon} alt={txt} style={iconStyle} />
			) : (
				<LeftAndRight>
					<FlexBox style={{ width: 40 }}>
						<Icon
							src={icon}
							alt={txt}
							style={{ ...iconStyle, margin: "0 auto" }}
						/>
					</FlexBox>
					<FlexBox style={{ justifyContent: "left", marginLeft: "1rem" }}>
						{txt}
					</FlexBox>
				</LeftAndRight>
			)}
		</Container>
	);
};

export default IconAndText;
