import React from "react";

type ContextType = {
	refreshToken: string | null;
	setRefreshToken: (refreshToken: string) => void;
	deleteRefreshToken: () => void;
};

export default React.createContext<ContextType>({
	refreshToken: "",
	setRefreshToken() {},
	deleteRefreshToken() {},
});
