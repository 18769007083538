import React, { Dispatch, SetStateAction } from "react";

export type SetAccessTokenType = Dispatch<SetStateAction<string | null>>;

type ContextType = {
	accessToken: string | null;
	setAccessToken: SetAccessTokenType;
};

export default React.createContext<ContextType>({
	accessToken: null,
	setAccessToken() {},
});
