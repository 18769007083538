import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import Panel from "../Panel";
import BarWithLabelForField from "../BarWithLabelForField";
import RemoteSelectField from "../RemoteSelectField";
import MultilineField from "../MultilineField";
import UniversitySelect from "../UniversitySelect";
import { Props } from "./types";
import getError from "./getError";
import GridItem from "./GridItem";
import InlineEditText from "../InlineEditText";
import TextField from "../TextField";
import InlineEditMultiline from "../InlineEditMultiline";
import YesNoButton from "../YesNoButton";
import RemoteMultipleSelectField from "../RemoteMultipleSelectField";

const MajorOneBasicPanel: FC<Props> = ({
	isEdit,
	errors,
	defaultValue: major,
	onChange,
}) => {
	return (
		<Panel title="學校與資訊科系">
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="學校">
						<UniversitySelect
							id="university-list"
							value={major.university_id}
							errors={getError(errors, "university_id")}
							onChange={(university) => {
								if (university !== null) {
									onChange("university_id", university.id);
								} else {
									onChange("university_id", "");
								}
							}}
						/>
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="學校類別">
						<RemoteSelectField
							id="type-of-school-list"
							url="type_of_school"
							defaultLabel="請選擇"
							error={getError(errors, "type_of_school_id")}
							disabled={false}
							value={major.type_of_school_id}
							onChange={(value) => onChange("type_of_school_id", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="等級">
						<RemoteSelectField
							id="level-list"
							url="level"
							defaultLabel="請選擇"
							error={getError(errors, "level_id")}
							disabled={false}
							value={major.level_id}
							onChange={(value) => onChange("level_id", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="領域">
						<RemoteMultipleSelectField
							id="field_of_study_ids"
							defaultLabel="請選擇"
							style={{ maxWidth: 385 }}
							error={getError(errors, "field_of_study_ids")}
							disabled={false}
							url="field_of_study"
							values={major.field_of_study_ids}
							onChange={(values) => {
								onChange("field_of_study_ids", values);
							}}
						/>
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="學位">
						<RemoteSelectField
							id="qualification-list"
							optionLabel="short_name"
							url="qualification"
							defaultLabel="請選擇"
							error={getError(errors, "qualification_id")}
							disabled={false}
							value={major.qualification_id}
							onChange={(value) => onChange("qualification_id", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="科系名稱">
						{isEdit ? (
							<InlineEditText
								id="major_name"
								placeholder="名稱"
								error={getError(errors, "name")}
								defaultValue={major.name}
								onConfirm={(value) => {
									onChange("name", value);
								}}
							/>
						) : (
							<TextField
								id="major_name"
								disabled={false}
								placeholder="名稱"
								readOnly={false}
								error={getError(errors, "name")}
								autoFocus={false}
								defaultValue={major.name}
								onChange={(value) => onChange("name", value)}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="科系網址">
						{isEdit ? (
							<InlineEditText
								id="major_website"
								placeholder="https://warwick.ac.uk"
								error={getError(errors, "website")}
								defaultValue={major.website}
								onConfirm={(value) => {
									onChange("website", value);
								}}
							/>
						) : (
							<TextField
								id="major_website"
								disabled={false}
								placeholder="https://warwick.ac.uk"
								readOnly={false}
								error={getError(errors, "website")}
								autoFocus={false}
								defaultValue={major.website}
								onChange={(value) => {
									onChange("website", value);
								}}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="學制">
						<RemoteSelectField
							id="pace-list"
							url="pace"
							defaultLabel="請選擇"
							error={getError(errors, "pace_id")}
							disabled={false}
							value={major.pace_id}
							onChange={(value) => onChange("pace_id", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="期間">
						<RemoteSelectField
							id="duration-list"
							url="duration"
							defaultLabel="請選擇"
							error={getError(errors, "duration_id")}
							disabled={false}
							value={major.duration_id}
							onChange={(value) => onChange("duration_id", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="國家">
						{major.country}
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="城市">{major.city}</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="地址">
						{major.address}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={12}>
					<BarWithLabelForField text="實習">
						<YesNoButton
							id="major_work_placement"
							checked={major.work_placement}
							onChange={(value) => onChange("work_placement", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={12}>
					<BarWithLabelForField text="畢業後申請工作簽證">
						<YesNoButton
							id="has_post_study_work_visa"
							checked={major.has_post_study_work_visa}
							onChange={(value) => onChange("has_post_study_work_visa", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={12}>
					<BarWithLabelForField text="學校與科系特色">
						{isEdit ? (
							<InlineEditMultiline
								id="major_features"
								placeholder=""
								error={getError(errors, "features")}
								defaultValue={major.features}
								onConfirm={(value) => onChange("features", value)}
							/>
						) : (
							<MultilineField
								id="major_features"
								disabled={false}
								placeholder=""
								readOnly={false}
								error={getError(errors, "features")}
								autoFocus={false}
								defaultValue={major.features}
								onChange={(value) => onChange("features", value)}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
		</Panel>
	);
};

export default MajorOneBasicPanel;
