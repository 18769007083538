import { useCallback, useState } from "react";
import Cookies from "js-cookie";

type ReturnType = {
	value: string | null;
	update: (newValue: string, options?: Cookies.CookieAttributes) => void;
	deleteCookie: () => void;
};

const useCookie = (cookieName: string): ReturnType => {
	const [value, setValue] = useState<string | null>(
		() => Cookies.get(cookieName) || null
	);

	const update = useCallback(
		(newValue: string, options?: Cookies.CookieAttributes) => {
			Cookies.set(cookieName, newValue, options);
			setValue(newValue);
		},
		[cookieName]
	);

	const deleteCookie = useCallback(() => {
		Cookies.remove(cookieName);
		setValue(null);
	}, [cookieName]);

	return { value, update, deleteCookie };
};

export default useCookie;
