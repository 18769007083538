import _ from "lodash";
import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import { Paper } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Pagination from "./Pagination";
import { useFindAll as useResourceFindAll } from "../../queries/resource.query";
import EllipsisText from "../EllipsisText";
import Loading from "../Loading";

const StyledTableCell = styled(TableCell)`
	&.MuiTableCell-head {
		background-color: rgb(108, 135, 198);
		color: white;
		font-weight: bold;
	}
`;

export type HeaderType = {
	id: string;
	align: "inherit" | "left" | "center" | "right" | "justify";
	label: string;
	style?: CSSProperties;
};

export type CellType = {
	id: string;
	align: "inherit" | "left" | "center" | "right" | "justify";
	style?: React.CSSProperties;
	isEllipsis?: boolean;
	component: any;
};

type ResultType = {
	id: string;
};

type Props = {
	headers: HeaderType[];
	cells: CellType[];
	page: number;
	rowsPerPage: number;
	onPageChange: (page: number) => void;
	onRowClick?: (row: ResultType, e: MouseEvent) => void;
	resource: string;
};

type FindAllResponseType = {
	results: ResultType[];
	total_count: number;
};

export const useFindAll = (
	resource: string,
	page: number,
	rowsPerPage: number
) => {
	return useResourceFindAll<void, FindAllResponseType>(resource, {
		page,
		limit: rowsPerPage,
	});
};

const KodenTable: FC<Props> = ({
	onRowClick,
	resource,
	headers,
	page,
	cells,
	rowsPerPage,
	onPageChange,
}) => {
	const findAll = useFindAll(resource, page, rowsPerPage);
	const isLoading = findAll.isLoading;
	const { results, total_count } = isLoading
		? { results: [], total_count: 0 }
		: findAll.data!;
	const isNotFound = total_count === 0 && _.isEmpty(results);

	return (
		<Paper>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{headers.map(({ id, align, label, style }) => {
								return (
									<StyledTableCell key={id} align={align} style={style}>
										{label}
									</StyledTableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading && (
							<TableRow>
								<TableCell
									colSpan={headers.length}
									style={{ display: "flex", justifyContent: "center" }}
								>
									<Loading />
								</TableCell>
							</TableRow>
						)}
						{!isLoading && isNotFound && (
							<TableRow>
								<TableCell colSpan={headers.length}>找不到資料</TableCell>
							</TableRow>
						)}
						{!isLoading &&
							results.map((result) => (
								<TableRow
									key={result.id}
									hover
									style={{ cursor: onRowClick !== undefined ? "pointer" : "" }}
									onClick={(e: unknown) => {
										if (onRowClick) {
											onRowClick(result, e as MouseEvent);
										}
									}}
								>
									{cells.map(
										(
											{ align, style, isEllipsis = false, component },
											index
										) => {
											return (
												<TableCell
													key={`${result.id}_${index}`}
													style={style}
													align={align}
												>
													{isEllipsis ? (
														<EllipsisText>{component(result)}</EllipsisText>
													) : (
														component(result)
													)}
												</TableCell>
											);
										}
									)}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination
				page={page}
				totalCount={total_count}
				rowsPerPage={rowsPerPage}
				onChange={onPageChange}
			/>
		</Paper>
	);
};

export default KodenTable;
