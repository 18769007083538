import usePage from "../../hooks/usePage";
import { useFindAll } from "../KodenTable/KodenTable";
import { rowsPerPage } from "./MajorList";
import useResource from "./useResource";

function useFindMajorAll() {
	const resource = useResource();
	const page = usePage();

	return useFindAll(resource, page, rowsPerPage);
}

export default useFindMajorAll;
