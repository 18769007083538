import React, { FC, useRef } from "react";
import { FcCheckmark } from "react-icons/fc";
import { VscError } from "react-icons/vsc";
import styled from "styled-components";
import { ClickAwayListener, Icon } from "@material-ui/core";
import TextField, { Props as TextProps } from "../TextField";
import Popper from "@material-ui/core/Popper";

interface Props
	extends Omit<
		TextProps,
		| "value"
		| "defaultValue"
		| "disabled"
		| "autoFocus"
		| "readOnly"
		| "onChange"
		| "onClick"
	> {
	defaultValue: string;
	onConfirm: (s: string) => void;
}

const ButtonIcon = styled(Icon)`
	cursor: pointer;
`;

const InlineEditText: FC<Props> = ({ defaultValue, onConfirm, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
	const ref = useRef(defaultValue);
	const inputRef = useRef<HTMLInputElement | undefined>();
	const open = Boolean(anchorEl);
	const id = open ? `${props.id}-transitions-popper` : undefined;

	const onOk = () => {
		onConfirm(ref.current);
		setAnchorEl(null);
	};

	const onCancel = () => {
		ref.current = defaultValue;
		if (inputRef && inputRef.current) {
			// @ts-ignore
			inputRef.current.value = defaultValue;
		}
		setAnchorEl(null);
	};

	return (
		<>
			<div>
				<ClickAwayListener
					onClickAway={() => {
						onCancel();
					}}
				>
					<div
						onClick={(event: React.MouseEvent<HTMLInputElement>) => {
							if (anchorEl === null) {
								setAnchorEl(event.currentTarget);
							}
						}}
					>
						<TextField
							{...props}
							inputRef={inputRef}
							disabled={false}
							autoFocus={open}
							readOnly={!open}
							defaultValue={defaultValue}
							onChange={(value) => {
								ref.current = value;
							}}
						/>
					</div>
				</ClickAwayListener>
			</div>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				transition
				placement={"bottom-end"}
			>
				<div
					style={{
						marginTop: 6,
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<ButtonIcon
						style={{ marginRight: 6 }}
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							onOk();
						}}
					>
						<FcCheckmark />
					</ButtonIcon>
					<ButtonIcon
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							onCancel();
						}}
					>
						<VscError style={{ color: "red" }} />
					</ButtonIcon>
				</div>
			</Popper>
		</>
	);
};

export default InlineEditText;
