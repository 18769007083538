import React, { FC } from "react";
import MajorOneBasicPanel from "./MajorOneBasicPanel";
import { Props } from "./types";
import MajorOneRankingPanel from "./MajorOneRankingPanel";
import MajorOneLanguagePanel from "./MajorOneLanguagePanel";
import MajorOneEntryInfoPanel from "./MajorOneEntryInfoPanel";
import MajorOneFeePanel from "./MajorOneFeePanel";

const MajorOne: FC<Props> = (props) => {
	return (
		<>
			<MajorOneBasicPanel {...props} />
			<MajorOneRankingPanel {...props} />
			<MajorOneFeePanel {...props} />
			<MajorOneLanguagePanel {...props} />
			<MajorOneEntryInfoPanel {...props} />
		</>
	);
};

export default MajorOne;
