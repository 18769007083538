export enum CategoryIdType {
	qualification = "qualification",
	level = "level",
	fieldOfStudy = "field_of_study",
	typeOfSchool = "type_of_school",
	pace = "pace",
	duration = "duration",
}

type CategoryType = {
	id: CategoryIdType;
	label: string;
	engLabel: string;
	value: string;
};

const categories: CategoryType[] = [
	{
		id: CategoryIdType.qualification,
		label: "學位",
		engLabel: "Qualification",
		value: "qualification",
	},
	{
		id: CategoryIdType.level,
		label: "等級",
		engLabel: "Level",
		value: "level",
	},
	{
		id: CategoryIdType.fieldOfStudy,
		label: "領域",
		engLabel: "Field of Study",
		value: "field_of_study",
	},
	{
		id: CategoryIdType.typeOfSchool,
		label: "學校類別",
		engLabel: "Type of School",
		value: "type_of_school",
	},
	{
		id: CategoryIdType.pace,
		label: "學制",
		engLabel: "Pace",
		value: "pace",
	},
	{
		id: CategoryIdType.duration,
		label: "期間",
		engLabel: "Duration",
		value: "duration",
	},
];

export default categories;
