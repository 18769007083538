import Utils from "../../utils";

const getImgValue = (value: string) => {
	if (!value) {
		return "";
	} else if (value.indexOf("data") !== -1) {
		return value;
	} else if (value.indexOf("http") === -1) {
		return Utils.getAbsoluteImageUrl(value);
	} else {
		return value;
	}
};

export default getImgValue;
