import { MajorOnePdfType } from "../../../types/majorTypes";
import { getSection } from "./getSection";

export function getRankingContent(major: MajorOnePdfType): any[] {
	const arr = [];
	if (major.ranking_qs !== 0) {
		arr.push("QS Ranking : " + major.ranking_qs);
	}
	if (major.ranking_guardian !== 0) {
		arr.push("Guardian Ranking : " + major.ranking_guardian);
	}
	if (major.ranking_complete_guide !== 0) {
		arr.push("Complete Guide : " + major.ranking_complete_guide);
	}
	if (major.ranking_us_news !== 0) {
		arr.push("US News : " + major.ranking_us_news);
	}

	// @ts-ignore
	let body = [];
	if (arr.length !== 0) {
		for (let i = 0, j = arr.length; i < j; i += 3) {
			let tempArr = arr.slice(i, i + 3);
			if (tempArr.length !== 3) {
				const newArr = new Array(3).fill("");
				tempArr.forEach((temp, j) => {
					newArr[j] = temp;
				});
				body.push(newArr);
			} else {
				body.push(tempArr);
			}
		}
	} else {
		body.push(["", "", ""]);
	}

	if (arr.length === 0) {
		return [];
	}

	return getSection("RANKING").concat({
		layout: "noBorders",
		style: "content",
		table: {
			headerRows: 1,
			widths: ["33%", "33%", "33%"],
			body,
		},
	});
}
