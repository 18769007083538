import React, { FC } from "react";
import { useNavigate } from "@reach/router";
import querystring from "query-string";
import styled from "styled-components";
import { path as uniListPath } from "../../routes/UniversityListRoute";
import { path as uniAddPath } from "../../routes/UniversityOneAddRoute";
import AddIconTextButton from "../AddIconTextButton";
import QueryBoard from "./QueryBoard";
import KodenTable from "../KodenTable";
import useHeaders from "./useHeaders";
import usePage from "../../hooks/usePage";
import useCells from "./useCells";
import { useFindAll } from "../KodenTable/KodenTable";
import useQueryParams from "./useQueryParams";
import SearchBar from "./SearchBar";

export const resource = "university";
export const rowsPerPage = 20;

const Container = styled.div`
	margin-top: 28px;

	& > *:not(:nth-child(1)) {
		margin-top: 20px;
	}
`;

export const useQueryResource = () => {
	const queryParams = useQueryParams();
	return `${resource}?${querystring.stringify(queryParams)}`;
};

export const useFindAllUniversity = () => {
	const page = usePage();
	const queryResource = useQueryResource();
	return useFindAll(queryResource, page, rowsPerPage);
};

const UniversityList: FC = () => {
	const page = usePage();
	const navigate = useNavigate();
	const headers = useHeaders();
	const cells = useCells();
	const queryParams = useQueryParams();
	const queryResource = useQueryResource();

	return (
		<Container>
			<QueryBoard />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<AddIconTextButton onClick={() => navigate(uniAddPath)} />
				<SearchBar />
			</div>
			<KodenTable
				headers={headers}
				cells={cells}
				page={page}
				rowsPerPage={rowsPerPage}
				resource={queryResource}
				onPageChange={async (page) => {
					if (page === 0) {
						const toQueryParams = { ...queryParams };
						delete toQueryParams.page;

						await navigate(
							`${uniListPath}?${querystring.stringify(toQueryParams)}`
						);
					} else {
						await navigate(
							`${uniListPath}?${querystring.stringify({
								...queryParams,
								page,
							})}`
						);
					}
				}}
			/>
		</Container>
	);
};

export default UniversityList;
