import React, { FC, ReactNode } from "react";
import {
	Button,
	Dialog as MaterialDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

type Props = {
	id: string;
	title: string;
	open: boolean;
	onOk: () => void;
	onClose: () => void;
	disabledOk: boolean;
	isLoading: boolean;
	dialogActions?: ReactNode;
};

const Dialog: FC<Props> = ({
	id,
	title,
	open,
	onOk,
	onClose,
	disabledOk,
	isLoading,
	children,
	dialogActions,
}) => {
	return (
		<MaterialDialog
			open={open}
			onClose={onClose}
			disableBackdropClick={isLoading}
			disableEscapeKeyDown={isLoading}
		>
			<DialogTitle id={id}>{title}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions>
				{dialogActions}
				<Button onClick={onClose} color="primary" disabled={isLoading}>
					取消
				</Button>
				<Button
					onClick={onOk}
					color="primary"
					disabled={disabledOk || isLoading}
				>
					{isLoading ? "處理中..." : "確定"}
				</Button>
			</DialogActions>
		</MaterialDialog>
	);
};

export default Dialog;
