import React, { FC, useState } from "react";
import { CircularProgress, FormHelperText } from "@material-ui/core";
import AvatarEditor from "../AvatarEditor";
import UploadButton from "../UploadButton";
import Utils from "../../utils";
import { OnChangeProps } from "../AvatarEditor/AvatarEditor";
import styled, { css } from "styled-components";
import getImgValue from "../AddDialog/getImgValue";

type DefaultValueType = {
	value: string;
	rotation: number;
	zoom: number;
	cut: string;
	positionX: number;
	positionY: number;
};

type Props = {
	defaultValue: DefaultValueType;
	error: string;
	onChange: OnChangeProps;

	label: string;
	disabled: boolean;
};

const Container = styled.div<{ disabled: boolean }>`
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.4;
		`}
`;

const ImageEditor: FC<Props> = ({
	error,
	onChange,
	defaultValue,
	label,
	disabled,
}) => {
	const [image, setImage] = useState<DefaultValueType>(defaultValue);
	const [loading, setLoading] = useState(false);
	const { value, rotation, zoom, positionX, positionY } = image;
	const isUploaded = value !== "";

	return (
		<Container disabled={disabled}>
			<div>
				{isUploaded ? (
					<>
						<AvatarEditor
							label={label}
							value={getImgValue(value)}
							defaultZoom={zoom}
							defaultRotation={rotation}
							defaultPosition={{ x: positionX, y: positionY }}
							onChange={(image) => {
								setImage((_) => image);
								onChange(image);
							}}
						/>
					</>
				) : (
					<div style={{ display: "flex", alignItems: "center" }}>
						<label style={{ marginRight: 20 }}>{label}</label>
						{loading && <CircularProgress />}
						<UploadButton
							maxInMB={10}
							image
							video={false}
							multiple={false}
							onFileTooBig={() => {
								Utils.alert("請上傳檔案小於10MB的檔案。");
							}}
							onFileUploadReady={() => {
								setLoading(true);
							}}
							onFileUploading={() => {
								setLoading(true);
							}}
							onFileUploaded={({ src }) => {
								const toImage = {
									value: src,
									rotation,
									zoom,
									cut: src,
									positionX,
									positionY,
								};
								setImage(toImage);
								onChange(toImage);
								setLoading(false);
							}}
							onFileUploadFailed={() => {
								Utils.alert("上傳失敗");
								setLoading(false);
							}}
						/>
					</div>
				)}
			</div>
			<FormHelperText error={error !== ""}>{error}</FormHelperText>
		</Container>
	);
};

export default ImageEditor;
