const queryKeys = {
	AUTH_LOGIN: "AUTH_LOGIN",
	RESOURCE_ADD: "RESOURCE_ADD",
	RESOURCE_FIND_ALL: "RESOURCE_FIND_ALL",
	RESOURCE_FIND_ONE: "RESOURCE_FIND_ONE",
	RESOURCE_UPDATE_COLUMN: "RESOURCE_UPDATE_COLUMN",
	RESOURCE_UPDATE_COLUMNS: "RESOURCE_UPDATE_COLUMNS",
	RESOURCE_UPDATE_RECORD: "RESOURCE_UPDATE_RECORD",
	RESOURCE_REMOVE: "RESOURCE_REMOVE",
	RESOURCE_CLONE: "RESOURCE_CLONE",
	DOWNLOAD_PDF: "DOWNLOAD_PDF",
	ME: "ME",
	PLACES_FIND_ALL: "PLACES_FIND_ALL",
};

export default queryKeys;
