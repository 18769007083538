import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import queryKeys from "./queryKeys";
import useAjax from "../hooks/useAjax";
import {
	ResourceUpdateColumnType,
	ResourceRemoveFieldType,
	ResourceType,
	ResourceUpdateColumnsType,
} from "../types/categoryTypes";
import { ErrorType } from "../types/statusErrorTypes";
import { RecordType } from "../types/recordType";

const url = "/";

export const getFindAllKey = (resource: string, params?: any) => {
	return [queryKeys.RESOURCE_FIND_ALL, resource, params];
};

export const getFindOneKey = (resource: string, id: string) => {
	return [queryKeys.RESOURCE_FIND_ONE, resource, id];
};

export async function refreshOne<T extends ResourceType>(
	queryClient: QueryClient,
	resource: string,
	data: T
) {
	await queryClient.setQueryData(
		[queryKeys.RESOURCE_FIND_ONE, resource, data.id],
		data
	);
}

export async function refreshList<T extends ResourceType>(
	queryClient: QueryClient,
	resource: string,
	data: T,
	params?: any
) {
	queryClient.setQueryData(getFindAllKey(resource, params), data);
}

export function useFindOne<TData = RecordType>(resource: string, id: string) {
	const enabled = id !== "";
	const ajax = useAjax();

	return useQuery<void, ErrorType, TData>(
		getFindOneKey(resource, id),
		() => ajax.get(`${url}${resource}/${id}`).then((e) => e.data),
		{ enabled }
	);
}

export function useFindAll<TQueryFnData, TData>(
	resource: string,
	params?: any
) {
	const ajax = useAjax();

	return useQuery<TQueryFnData, ErrorType, TData>(
		getFindAllKey(resource, params),
		() => ajax.get(`${url}${resource}`, { params }).then((e) => e.data)
	);
}

export function useAddMutation<TData, TVariables>(
	resource: string,
	params?: any
) {
	const queryClient = useQueryClient();
	const ajax = useAjax();

	return useMutation<TData, ErrorType, TVariables>(
		queryKeys.RESOURCE_ADD,
		(variables) => {
			return ajax.post(`${url}${resource}`, variables).then((e) => e.data);
		},
		{
			async onSuccess(data) {
				// @ts-ignore
				await refreshList(queryClient, resource, data, params);
			},
		}
	);
}

export function usePatchMutation<TData, TVariables>(resource: string) {
	const ajax = useAjax();

	return useMutation<TData, ErrorType, TVariables>(
		queryKeys.RESOURCE_UPDATE_RECORD,
		(variables) => {
			return ajax.patch(`${url}${resource}`, variables).then((e) => e.data);
		}
	);
}

export function useUpdateColumnMutation<T extends ResourceType>(
	resource: string,
	params?: any
) {
	const ajax = useAjax();

	return useMutation<T, ErrorType, ResourceUpdateColumnType>(
		queryKeys.RESOURCE_UPDATE_COLUMN,
		({ id, column, value }) => {
			return ajax
				.put(`${url}${resource}/${id}`, { column, value })
				.then((e) => e.data);
		}
	);
}

export function useUpdateColumnsMutation<T extends ResourceType>(
	resource: string,
	resourceName?: string
) {
	const ajax = useAjax();
	return useMutation<T, ErrorType, ResourceUpdateColumnsType>(
		queryKeys.RESOURCE_UPDATE_COLUMNS,
		({ id, values }) => {
			let ajaxUrl = `${url}${resource}/${id}`;
			if (resourceName !== undefined) {
				ajaxUrl += `/${resourceName}`;
			}

			return ajax.patch(ajaxUrl, values).then((e) => e.data);
		}
	);
}

export const useUpdateRecordMutation = (resource: string, params?: any) => {
	const queryClient = useQueryClient();
	const ajax = useAjax();

	return useMutation<ResourceType, ErrorType, RecordType>(
		queryKeys.RESOURCE_UPDATE_RECORD,
		(record) => {
			return ajax
				.put(`${url}${resource}/${record.id}`, record)
				.then((e) => e.data);
		},
		{
			async onSuccess(data) {
				await refreshList(queryClient, resource, data, params);
			},
		}
	);
};

export const useRemoveMutation = (resource: string, params?: any) => {
	const queryClient = useQueryClient();
	const ajax = useAjax();

	return useMutation<ResourceType, ErrorType, ResourceRemoveFieldType>(
		queryKeys.RESOURCE_REMOVE,
		({ id }) => {
			return ajax.delete(`${url}${resource}/${id}`).then((e) => e.data);
		},
		{
			async onSuccess(data) {
				await refreshList(queryClient, resource, data, params);
			},
		}
	);
};

export const useCloneMutation = (resource: string, params?: any) => {
	const queryClient = useQueryClient();
	const ajax = useAjax();

	return useMutation<ResourceType, ErrorType, ResourceRemoveFieldType>(
		queryKeys.RESOURCE_CLONE,
		({ id }) => {
			return ajax.post(`${url}${resource}/${id}/clone`).then((e) => e.data);
		},
		{
			async onSuccess(data) {
				await refreshList(queryClient, resource, data, params);
			},
		}
	);
};
