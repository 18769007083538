import styled from "styled-components";

const DottedBox = styled.div`
	width: 75px;
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23707070' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	outline: none;

	&:hover {
		cursor: pointer;
	}
`;

export default DottedBox;
