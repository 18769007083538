import React, { FC, useState } from "react";
import { useNavigate } from "@reach/router";
import styled from "styled-components";
import AddIconTextButton from "../AddIconTextButton";
import { path } from "../../routes/UserOneAddRoute";
import { path as userListPath } from "../../routes/UserListRoute";
import { useUpdateColumnMutation } from "../../queries/resource.query";
import ChangePasswordDialog from "../ChangePasswordDialog";
import usePage from "../../hooks/usePage";
import querystring from "query-string";
import KodenTable from "../KodenTable";
import useHeaders from "./useHeaders";
import useCells from "./useCells";
import { prefixPath } from "../../routes/UserOneEditRoute";

const resource = "user";
export const rowsPerPage = 20;

const Container = styled.div`
	& > *:nth-child(1) {
		margin-bottom: 1rem;
	}
`;

const UserList: FC = () => {
	const page = usePage();
	const changePasswordMutation = useUpdateColumnMutation(resource);
	const [userId, setUserId] = useState("");
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const headers = useHeaders();
	const cells = useCells((id) => {
		setUserId(id);
		setOpen(true);
	});

	return (
		<Container>
			<AddIconTextButton
				onClick={async () => {
					await navigate(path);
				}}
			/>
			{open && (
				<ChangePasswordDialog
					open={open}
					onClose={() => setOpen(false)}
					onSubmit={(pw_1) => {
						changePasswordMutation.mutate(
							{ id: userId, column: "password", value: pw_1 },
							{
								async onSuccess() {
									setOpen(false);
									setUserId("");
									window.alert(`密碼更新成功`);
								},
							}
						);
					}}
				/>
			)}
			<KodenTable
				headers={headers}
				cells={cells}
				resource={resource}
				page={page}
				rowsPerPage={rowsPerPage}
				onRowClick={async ({ id }) => {
					await navigate(`${prefixPath}/${id}`);
				}}
				onPageChange={async (page) => {
					if (page === 0) {
						await navigate(userListPath);
					} else {
						await navigate(
							`${userListPath}?${querystring.stringify({
								page,
							})}`
						);
					}
				}}
			/>
		</Container>
	);
};

export default UserList;
