import _ from "lodash";
import { StateMapType } from "../../hooks/useStateMap";
import ColumnType, { ColumnIdType } from "./columnType";

export type ErrorsType = Record<ColumnIdType, string>;

const getErrors = (
	stateMap: StateMapType,
	columns: ColumnType[]
): ErrorsType => {
	const columnMap = _.mapKeys(columns, "id");
	const errors: ErrorsType = {};

	Object.keys(stateMap).forEach((columnId) => {
		const column = columnMap[columnId];
		const value = stateMap[columnId];

		if (column.isVisible && value === column.defaultValue) {
			errors[columnId] = column.emptyError;
		} else {
			errors[columnId] = "";
		}
	});
	return errors;
};

export default getErrors;
