import React from "react";
import { RouteComponentProps } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as homePath, title as homeTitle } from "./HomeRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import MajorOneAdd from "../components/MajorOneAdd";

export const path = "/new-major";
export const title = "新增學校與科系資訊";

const MajorOneAddRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={homeTitle}
							preLink={homePath}
							title={title}
						/>
					}
				>
					<MajorOneAdd />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default MajorOneAddRoute;
