import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { useQueryClient } from "react-query";
import {
	refreshOne,
	useFindOne,
	useUpdateColumnMutation,
} from "../../queries/resource.query";
import UserOne from "../UserOne";
import { UserOneFindType, UserType } from "../../types/userTypes";
import _ from "lodash";

const resource = "user";

const UserOneEdit = (ignored: RouteComponentProps) => {
	const queryClient = useQueryClient();
	const { id } = useParams();
	const user = useFindOne<UserOneFindType>(resource, id);
	const updateColumnMutation =
		useUpdateColumnMutation<UserOneFindType>(resource);

	if (user.isLoading) {
		return <Fragment />;
	}

	return (
		<UserOne
			isEdit={true}
			errors={{}}
			value={user.data as UserType}
			onChange={_.debounce((field, value) => {
				updateColumnMutation.mutate(
					{
						id,
						column: field,
						value,
					},
					{
						async onSuccess(data) {
							await refreshOne<UserOneFindType>(queryClient, resource, data);
						},
						async onError(data) {
							window.alert(data.response.data.msg);
						},
					}
				);
			}, 500)}
		/>
	);
};

export default UserOneEdit;
