import React, { Fragment } from "react";
import styled from "styled-components";
import FlexBox from "../FlexBox";
import { useMe } from "../../queries/me.query";

const Container = styled(FlexBox)`
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
`;

const Me = () => {
	const me = useMe();

	if (me.isLoading) {
		return <Fragment />;
	}

	return <Container></Container>;
};

export default Me;
