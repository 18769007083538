import React from "react";
import { RouteComponentProps } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as listPath, title as listTitle } from "./UserListRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import UserOneAdd from "../components/UserOneAdd";

export const path = "/new-user";
export const title = "新增帳號";

const UserOneAddRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={listTitle}
							preLink={listPath}
							title={title}
						/>
					}
				>
					<UserOneAdd />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UserOneAddRoute;
