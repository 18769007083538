import React, { FC } from "react";
import Panel from "../Panel";
import BarWithLabelForField from "../BarWithLabelForField";
import { Props } from "./types";
import GridItem from "./GridItem";
import { Grid } from "@material-ui/core";

const MajorOneRankingPanel: FC<Props> = ({ defaultValue: major }) => {
	return (
		<Panel title="排名">
			<Grid container spacing={1}>
				<GridItem xs={4}>
					<BarWithLabelForField text="QS Ranking">
						{major.ranking_qs}
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="Guardian Ranking">
						{major.ranking_guardian}
					</BarWithLabelForField>
				</GridItem>
				<GridItem xs={4}>
					<BarWithLabelForField text="Complete Guide">
						{major.ranking_complete_guide}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid item xs={4} style={{ marginTop: 16 }}>
				<BarWithLabelForField text="US News">
					{major.ranking_us_news}
				</BarWithLabelForField>
			</Grid>
		</Panel>
	);
};

export default MajorOneRankingPanel;
