import React, { CSSProperties } from "react";
import Select from "@material-ui/core/Select";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select/Select";

export type OptionType = {
	id: string;
	value: string;
	label: string;
	disabled?: boolean;
};

export type Props = {
	id: string;
	className?: string;
	style?: CSSProperties;
	disabled: boolean;
	options: OptionType[];
	defaultLabel: string;
	defaultOptionValue?: any;
	value?: string;
	defaultValue?: string;
	error: string;
	label?: string;
	onChange: (value: string) => void;
};

const SelectField = React.forwardRef(
	(
		{
			id,
			className,
			style,
			disabled,
			options,
			defaultLabel,
			defaultOptionValue = "",
			error,
			label,
			value,
			defaultValue,
			onChange,
		}: Props,
		ref
	) => {
		const labelId = `${id}_label_id`;
		const hasError = error !== "";

		const selectProps: SelectProps = {
			autoWidth: true,
			native: true,
			onChange(e) {
				onChange(e.target.value as string);
			},
		};
		if (ref) {
			selectProps.inputProps = {
				inputRef: ref,
			};
		}
		if (value !== undefined) {
			selectProps.value = value;
		}
		if (defaultValue !== undefined) {
			selectProps.defaultValue = defaultValue;
		}

		return (
			<FormControl error={hasError} disabled={disabled}>
				{label && (
					<InputLabel shrink id={labelId}>
						{label}
					</InputLabel>
				)}
				<Select
					{...selectProps}
					labelId={labelId}
					className={className}
					style={style}
				>
					<option value={defaultOptionValue}>{defaultLabel}</option>
					{options.map(({ id: optionId, value, label, disabled = false }) => {
						return (
							<option
								key={`select-${id}_${optionId}`}
								disabled={disabled}
								value={value}
							>
								{label}
							</option>
						);
					})}
				</Select>
				{hasError && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		);
	}
);

export default SelectField;
