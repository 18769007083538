import React from "react";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import { RouteComponentProps } from "@reach/router";
import MainLayout from "../components/MainLayout";
import UserList from "../components/UserList";
import LayoutContent from "../components/MainLayout/LayoutContent";

export const path = "/users";
export const title = "帳號管理";

const UserListRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent title={title} data-test-id={path}>
					<UserList />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UserListRoute;
