import { Dispatch, SetStateAction, useState } from "react";
import ColumnType, { ColumnIdType } from "../components/AddDialog/columnType";

export type DefaultValueType = string | number;
export type StateMapType = Record<ColumnIdType, DefaultValueType>;
export type SetStateMapType = Dispatch<SetStateAction<StateMapType>>;
type UseStateType = (columns: ColumnType[]) => [StateMapType, SetStateMapType];

export const getEmptyStateMap = (columns: ColumnType[]) => {
	const obj: StateMapType = {};
	columns.forEach(({ id, defaultValue }) => {
		obj[id] = defaultValue;
	});
	return obj;
};

const useStateMap: UseStateType = (columns) => {
	const [stateMap, setStateMap] = useState<StateMapType>(
		getEmptyStateMap(columns)
	);
	return [stateMap, setStateMap];
};

export default useStateMap;
