import React, { FC, Fragment } from "react";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import {
	refreshList,
	useRemoveMutation,
	useUpdateColumnMutation,
} from "../../queries/resource.query";
import BinIcon from "../Binicon";
import { OptionResponseType } from "../../types/optionTypes";
import useStateMap from "../../hooks/useStateMap";
import EditIcon from "../EditIcon";
import useSearchObject from "../../hooks/useSearchObject";
import ColumnType from "../AddDialog/columnType";
import InputType from "../AddDialog/inputTypes";
import UniversityLogo from "../UniversityLogo";
import InlineEditText from "../InlineEditText";

const Container = styled.div`
	display: flex;
	align-items: center;

	& > div {
		width: var(--panel-column-width);
	}

	> *:nth-of-type(n + 2) {
		margin-left: 2rem;
	}
`;

type Props = {
	resource: string;
	editColumn: boolean;
	editDialog: boolean;
	columns: ColumnType[];
	option: OptionResponseType;
	onEditClick: (recordId: string) => void;
};

const PanelListRow: FC<Props> = ({
	resource,
	editColumn,
	editDialog,
	columns,
	option,
	onEditClick,
}) => {
	const recordId = option.id;
	const queryClient = useQueryClient();
	const params = useSearchObject();
	const [errorMap, setErrorMap] = useStateMap(columns);
	const updateMutation = useUpdateColumnMutation(resource, params);
	const removeMutation = useRemoveMutation(resource, params);

	const onRemoteUpdate = (
		columnId: string,
		value: string,
		defaultValue: string
	) => {
		if (value !== defaultValue) {
			updateMutation.mutate(
				{ id: recordId, value, column: columnId },
				{
					async onSuccess(data) {
						await refreshList(queryClient, resource, data, params);
						setErrorMap((errorMap) => {
							return {
								...errorMap,
								[columnId]: "",
							};
						});
					},
					async onError(data) {
						setErrorMap((errorMap) => {
							return {
								...errorMap,
								[columnId]: data.response.data.msg,
							};
						});
					},
				}
			);
		}
	};

	return (
		<Container>
			{columns.map((column) => {
				const columnId = column.id;
				const key = `category_one_item_${resource}_${columnId}_${recordId}`;
				// @ts-ignore
				const defaultValue = option[columnId];

				if (editColumn && column.isVisible) {
					return (
						<InlineEditText
							key={key}
							id={`category_one_item_${resource}_${columnId}_${recordId}`}
							defaultValue={defaultValue}
							error={errorMap[columnId] as string}
							onConfirm={(value) => {
								onRemoteUpdate(columnId, value, defaultValue);
							}}
						/>
					);
				} else if (column.isVisible) {
					if (column.inputType === InputType.image) {
						return <UniversityLogo key={key} value={defaultValue} />;
					} else {
						return <div key={key}>{defaultValue}</div>;
					}
				} else {
					return <Fragment key={key} />;
				}
			})}
			{editDialog && <EditIcon onClick={() => onEditClick(recordId)} />}
			<BinIcon
				onClick={() => {
					if (window.confirm("確定要刪除嗎？")) {
						removeMutation.mutate({ id: recordId });
					}
				}}
			/>
		</Container>
	);
};

export default PanelListRow;
