import useSubmitMajorMutation from "../MajorOne/useSubmitMajorMutation";
import { UniversityErrorsType } from "../../types/universityType";

type Type = (errors: UniversityErrorsType) => boolean;

const useButtonDisabled: Type = (errors) => {
	const addMutation = useSubmitMajorMutation();
	const submit = useSubmitMajorMutation();
	const hasError = !!Object.values(errors).find((e) => e !== "");

	return addMutation.isLoading || hasError || submit.isLoading;
};

export default useButtonDisabled;
