import React from "react";
import Me from "../components/Me";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import { RouteComponentProps } from "@reach/router";
import MainLayout from "../components/MainLayout";

export const path = "/me";

const MeRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<div data-test-id="me-route">
					<Me />
				</div>
			</MainLayout>
		);
	}
};

export default MeRoute;
