import React from "react";
import Login from "../components/Login";
import { RouteComponentProps } from "@reach/router";

export const path = "/login";

const LoginRoute = (ignored: RouteComponentProps) => {
	return <Login />;
};

export default LoginRoute;
