import HomeRoute, { path as HomePath } from "./HomeRoute";
import MajorOneAddRoute, { path as MajorOneAddPath } from "./MajorOneAddRoute";
import MajorOneEditRoute, {
	path as MajorOneEditPath,
} from "./MajorOneEditRoute";
import MajorOneEditScreenshotRoute, {
	path as MajorOneEditScreenshotPath,
} from "./MajorOneEditScreenshotRoute";
import UserOneEditRoute, { path as UserOneEditPath } from "./UserOneEditRoute";
import LoginRoute, { path as LoginPath } from "./LoginRoute";
import UserListRoute, { path as UserListPath } from "./UserListRoute";
import UserOneAddRoute, { path as UserOneAddPath } from "./UserOneAddRoute";
import UniversityOneAddRoute, {
	path as UniversityOneAddPath,
} from "./UniversityOneAddRoute";
import UniversityOneEditRoute, {
	path as UniversityOneEditPath,
} from "./UniversityOneEditRoute";
import UniversityListRoute, { path as UniPath } from "./UniversityListRoute";
import CategoryListRoute, {
	path as CategoryListPath,
} from "./CategoryListRoute";
import MeRoute, { path as MePath } from "./MeRoute";
import CategoryOneRoute, { path as CategoryOnePath } from "./CategoryOneRoute";

const routes = [
	{
		id: "HomeRoute",
		path: HomePath,
		component: HomeRoute,
	},
	{
		id: "MajorOneAddPath",
		path: MajorOneAddPath,
		component: MajorOneAddRoute,
	},
	{
		id: "MajorOneEditPath",
		path: MajorOneEditPath,
		component: MajorOneEditRoute,
	},
	{
		id: "MajorOneEditScreenshotPath",
		path: MajorOneEditScreenshotPath,
		component: MajorOneEditScreenshotRoute,
	},
	{
		id: "LoginRoute",
		path: LoginPath,
		component: LoginRoute,
	},
	{
		id: "MeRoute",
		path: MePath,
		component: MeRoute,
	},
	{
		id: "UserListRoute",
		path: UserListPath,
		component: UserListRoute,
	},
	{
		id: "UserOneEditRoute",
		path: UserOneEditPath,
		component: UserOneEditRoute,
	},
	{
		id: "UserOneAddRoute",
		path: UserOneAddPath,
		component: UserOneAddRoute,
	},
	{
		id: "UniversityListRoute",
		path: UniPath,
		component: UniversityListRoute,
	},
	{
		id: "UniversityOneAddRoute",
		path: UniversityOneAddPath,
		component: UniversityOneAddRoute,
	},
	{
		id: "UniversityOneEditRoute",
		path: UniversityOneEditPath,
		component: UniversityOneEditRoute,
	},
	{
		id: "CategoryListRoute",
		path: CategoryListPath,
		component: CategoryListRoute,
	},
	{
		id: "CategoryOneRoute",
		path: CategoryOnePath,
		component: CategoryOneRoute,
	},
];

export default routes;
