import React, { FC } from "react";
import KodenSelect from "../KodenSelect";
import { useFindAll } from "../../queries/resource.query";

type Props = {
	value: string;
	onChange: (s: string) => void;
};

const MajorNameKodenSelect: FC<Props> = ({ value = "", onChange }) => {
	const findAll = useFindAll<unknown, string[]>("major/name");
	let options = [{ id: "", value: "", label: "名稱" }];

	if (findAll.isFetched) {
		const data = findAll.data!.map((e) => {
			return { id: e, label: e, value: e };
		});
		options = options.concat(data);
	}
	return <KodenSelect options={options} value={value} onChange={onChange} />;
};

export default MajorNameKodenSelect;
