import React, { forwardRef, MutableRefObject, useRef } from "react";
import { TextField as MaterialTextField } from "@material-ui/core";
import { onBlurType, onChangeType, onClickType } from "../../types/htmlTypes";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import usePrevious from "../../hooks/usePrevious";

export type Props = {
	id: string;
	disabled: boolean;
	label?: string;
	placeholder?: string;
	value?: string;
	defaultValue?: string;
	error: string;
	autoFocus: boolean;
	readOnly: boolean;
	multiline?: boolean;
	rows?: number;
	inputProps?: Partial<StandardInputProps>;
	inputRef?: MutableRefObject<HTMLInputElement | undefined>;
	onChange?: onChangeType;
	onBlur?: onBlurType;
	onEnter?: (value: string) => void;
	onClick?: onClickType;
	type?: "text" | "email" | "password";
	className?: string;
	style?: React.CSSProperties;
};

const TextField = forwardRef(
	(
		{
			id,
			disabled,
			placeholder,
			label = "",
			readOnly = false,
			multiline = false,
			rows,
			error,
			autoFocus,
			value,
			defaultValue,
			inputRef: propsInputRef,
			onChange,
			onBlur,
			onEnter,
			onClick,
			inputProps = {},
			type = "text",
			className,
			style,
		}: Props,
		ref
	) => {
		const preDefaultValue = usePrevious(defaultValue);
		const theRef = useRef();
		const inputRef = propsInputRef || theRef;
		const isComposing = React.useRef(false);
		const materialTextFieldProps: TextFieldProps = {
			className,
			style,
			margin: "dense",
			id,
			type,
			autoFocus,
			disabled,
			placeholder,
			label,
			inputRef,
			multiline,
			rows: multiline ? rows : 0,
			fullWidth: true,
			InputProps: { ...inputProps, readOnly },
			onCompositionStartCapture() {
				isComposing.current = true;
			},
			onCompositionUpdateCapture() {
				isComposing.current = true;
			},
			onCompositionEndCapture() {
				isComposing.current = false;
			},
			error: error !== "",
			helperText: error,
		};

		if (value !== undefined) {
			materialTextFieldProps.value = value;
		}
		if (defaultValue !== undefined) {
			materialTextFieldProps.defaultValue = defaultValue;
		}

		if (onBlur !== undefined) {
			materialTextFieldProps.onBlur = (event) => {
				onBlur(event.target.value, event);
			};
		}
		if (onChange !== undefined) {
			materialTextFieldProps.onChange = (e) => {
				onChange(e.target.value, e);
			};
		}
		if (onEnter !== undefined) {
			materialTextFieldProps.onKeyPress = (e) => {
				if (e.key === "Enter" && inputRef.current) {
					// @ts-ignore
					onEnter(inputRef.current.value);
				}
			};
		}
		if (onClick !== undefined) {
			materialTextFieldProps.onClick = (e) => {
				// @ts-ignore
				onClick(e.target.value, e);
			};
		}

		if (preDefaultValue !== defaultValue && inputRef && inputRef.current) {
			// @ts-ignore
			inputRef.current.value = defaultValue;
		}

		// @ts-ignore
		return <MaterialTextField {...materialTextFieldProps} ref={ref} />;
	}
);

export default TextField;
