import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as homePath, title as homeTitle } from "./HomeRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import { useFindOne } from "../queries/resource.query";
import { MajorOneFindType } from "../types/majorTypes";
import MajorOneEdit from "../components/MajorOneEdit";

export const prefixPath = "/major";
export const path = `${prefixPath}/:id`;
const resource = "major";

const MajorOneEditRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();
	const { id } = useParams();
	const major = useFindOne<MajorOneFindType>(resource, id);

	if (!isLogin) {
		return <LoginRoute />;
	} else if (major.isLoading) {
		return <Fragment />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={homeTitle}
							preLink={homePath}
							title={major.data!.name}
						/>
					}
				>
					<MajorOneEdit />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default MajorOneEditRoute;
