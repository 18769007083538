import _ from "lodash";
import React, { FC } from "react";
import NumberField from "../NumberField";
import getError from "./getError";
import {
	UniversityErrorsType,
	UniversityType,
} from "../../types/universityType";
import { UniversityField } from "./types";

type Props = {
	isEdit: boolean;
	errors: UniversityErrorsType;
	defaultValue: UniversityType;
	onChange: (field: UniversityField, value: any) => void;
};

const UniversityOneRankingFields: FC<Props> = ({
	isEdit,
	errors,
	defaultValue: major,
	onChange,
}) => {
	const debounceValue = isEdit ? 500 : 0;

	return (
		<div>
			<NumberField
				id="qs_ranking"
				label="QS Ranking"
				min={0}
				max={1000}
				step={1}
				disabled={false}
				readOnly={false}
				autoFocus={false}
				error={getError(errors, "ranking_qs")}
				defaultValue={major.ranking_qs}
				onChange={_.debounce(
					(value) => onChange("ranking_qs", value),
					debounceValue
				)}
			/>
			<NumberField
				id="guardian_ranking"
				label="Guardian Ranking"
				min={0}
				max={1000}
				step={1}
				disabled={false}
				readOnly={false}
				autoFocus={false}
				error={getError(errors, "ranking_guardian")}
				defaultValue={major.ranking_guardian}
				onChange={_.debounce(
					(value) => onChange("ranking_guardian", value),
					debounceValue
				)}
			/>
			<NumberField
				id="complete_guide"
				label="Complete Guide"
				min={0}
				max={1000}
				step={1}
				disabled={false}
				readOnly={false}
				autoFocus={false}
				error={getError(errors, "ranking_complete_guide")}
				defaultValue={major.ranking_complete_guide}
				onChange={_.debounce(
					(value) => onChange("ranking_complete_guide", value),
					debounceValue
				)}
			/>
			<NumberField
				id="us_news"
				label="US News"
				min={0}
				max={1000}
				step={1}
				disabled={false}
				readOnly={false}
				autoFocus={false}
				error={getError(errors, "ranking_us_news")}
				defaultValue={major.ranking_us_news}
				onChange={_.debounce(
					(value) => onChange("ranking_us_news", value),
					debounceValue
				)}
			/>
		</div>
	);
};

export default UniversityOneRankingFields;
