import React, { FC, useState } from "react";
import Dialog from "../Dialog";
import getErrors from "./getErrors";
import getDisabledOk from "./getDisabledOk";
import { useAddMutation } from "../../queries/resource.query";
import useStateMap, {
	getEmptyStateMap,
	StateMapType,
} from "../../hooks/useStateMap";
import DialogColumns from "./DialogColumns";
import ColumnType from "./columnType";
import getHasError from "./getHasError";
import useSearchObject from "../../hooks/useSearchObject";

type Props = {
	title: string;
	resource: string;
	columns: ColumnType[];
	open: boolean;
	onClose: () => void;
	onAddSuccess?: () => void;
};

const AddDialog: FC<Props> = ({
	title,
	columns,
	resource,
	open,
	onClose,
	onAddSuccess,
}) => {
	const searchObject = useSearchObject();
	const addMutation = useAddMutation<unknown, StateMapType>(
		resource,
		searchObject
	);
	const [dirty, setDirty] = useState(false);
	const [stateMap, setStateMap] = useStateMap(columns);
	const [errorMsg, setErrorMsg] = useState("");
	const errors = getErrors(stateMap, columns);
	const hasError = getHasError(errors);
	const disabledOk = getDisabledOk({
		hasError,
		dirty,
		isLoading: addMutation.isLoading,
	});

	const onDialogClose = () => {
		setDirty(false);
		setStateMap(getEmptyStateMap(columns));
		setErrorMsg("");
		onClose();
	};

	return (
		<Dialog
			id={`Dialog_${resource}_add`}
			title={title}
			open={open}
			onClose={onDialogClose}
			onOk={() => {
				setDirty(true);
				if (!hasError) {
					addMutation.mutate(stateMap, {
						onSuccess() {
							onDialogClose();

							if (onAddSuccess) {
								onAddSuccess();
							}
						},
						onError(data) {
							setErrorMsg(data.response.data.msg);
						},
					});
				}
			}}
			disabledOk={disabledOk}
			isLoading={addMutation.isLoading}
		>
			<DialogColumns
				columns={columns}
				setStateMap={setStateMap}
				disabled={addMutation.isLoading}
				dirty={dirty}
				errors={errors}
				errorMsg={errorMsg}
				stateMap={stateMap}
			/>
		</Dialog>
	);
};

export default AddDialog;
