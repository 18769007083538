import React, { FC } from "react";
import NumberField, { Props as NumberFieldProps } from "../NumberField";

interface Props extends Omit<NumberFieldProps, "min" | "max" | "step"> {}

const TOEFLScoreField: FC<Props> = (props) => {
	return <NumberField {...props} min={0} max={30} step={1} />;
};

export default TOEFLScoreField;
