import _ from "lodash";
import { useContext } from "react";
import RefreshTokenContext from "../contexts/RefreshTokenContext";

const useIsLogin = (): boolean => {
	const { refreshToken } = useContext(RefreshTokenContext);

	return !_.isEmpty(refreshToken);
};

export default useIsLogin;
