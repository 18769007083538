import _ from "lodash";
import React, { CSSProperties } from "react";
import Select from "@material-ui/core/Select";
import {
	Checkbox,
	FormControl,
	FormHelperText,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
} from "@material-ui/core";

export type OptionType = {
	id: string;
	value: string;
	label: string;
	disabled?: boolean;
};

export type Props = {
	id: string;
	className?: string;
	style?: CSSProperties;
	disabled: boolean;
	options: OptionType[];
	defaultLabel: string;
	defaultOptionValue?: any;
	values?: string[];
	defaultValues?: string[];
	error: string;
	label?: string;
	onChange: (values: string[]) => void;
};

const MultipleSelectField = React.forwardRef(
	(
		{
			id,
			className,
			style,
			disabled,
			options,
			defaultLabel,
			defaultOptionValue = "",
			error,
			label,
			defaultValues = [],
			values = [],
			onChange,
		}: Props,
		ref
	) => {
		const labelId = `${id}_label_id`;
		const hasError = error !== "";

		const map = _.mapKeys(options, "value");
		const renderValue = (selected: unknown) => {
			const selectedValue = selected as string[];

			if (selectedValue.length === 0) {
				return <div>{defaultLabel}</div>;
			} else {
				return selectedValue
					.map((value: string) => {
						return map[value]?.label;
					})
					.join(", ");
			}
		};

		return (
			<FormControl error={hasError} disabled={disabled}>
				{label && (
					<InputLabel shrink id={labelId}>
						{label}
					</InputLabel>
				)}
				<Select
					id={id}
					className={className}
					style={style}
					disabled={disabled}
					multiple
					displayEmpty
					value={values}
					labelId={labelId}
					input={<Input />}
					renderValue={renderValue}
					onChange={(event) => {
						onChange(event.target.value as string[]);
					}}
				>
					{options.map(({ id: optionId, value, label, disabled = false }) => {
						const checked = !!values.find((e) => e === value);
						return (
							<MenuItem
								key={`select-${id}_${optionId}`}
								value={value}
								disabled={disabled}
							>
								<Checkbox checked={checked} />
								<ListItemText primary={label} />
							</MenuItem>
						);
					})}
				</Select>
				{hasError && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		);
	}
);

export default MultipleSelectField;
