import React, { FC, memo, useEffect, useRef } from "react";
import getLongCity from "./getLongCity";
import getLongCountry from "./getLongCountry";
import getShortCountry from "./getShortCountry";
import getShortCity from "./getShortCity";
import getLat from "./getLat";
import getLng from "./getLng";

type Props = {
	result: google.maps.GeocoderResult;
};

const Wrapper: FC<Props> = ({ result }) => {
	const ref = useRef(null);
	const position = {
		lat: getLat(result),
		lng: getLng(result),
	};

	const shortCountry = getShortCountry(result);
	const longCountry = getLongCountry(result);
	const shortCity = getShortCity(result);
	const longCity = getLongCity(result);

	useEffect(() => {
		if (ref.current) {
			const map = new google.maps.Map(ref.current!, {
				center: position,
				zoom: 11,
			});

			const infoWindow = new google.maps.InfoWindow({
				content: `
					<table>
						<tr>
							<td>Country: ${longCountry}(${shortCountry})</td>
						</tr>
						<tr>
							<td>City: ${longCity}(${shortCity})</td>
						</tr>
						<tr>
							<td>Address: ${result.formatted_address}</td>
						</tr>
					</table>
				`,
			});

			const marker = new google.maps.Marker({ position, map });

			infoWindow.open({
				anchor: marker,
				map,
				shouldFocus: true,
			});
		}
	});

	return <div ref={ref} style={{ height: 250, width: 500 }} />;
};

export default memo(Wrapper);
