import React, { FC } from "react";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import IconAndText from "./IconAndText";
import useList from "./useList";
import MyUserAvatar from "../MyUserAvatar";

const Container = styled.div<{ open: boolean }>`
	padding-top: 1rem;
	z-index: 1;

	border-right: solid 0.5px #c0c0c0;
	background-color: #ffffff;
	width: ${(props) =>
		props.open ? "calc(var(--sidebar-width)*2)" : "var(--sidebar-width)"};
	height: 100%;

	display: grid;
	grid-template-rows: repeat(5, 3rem) 1px 3rem;

	position: fixed;
	top: var(--header-height);
	left: 0;
`;

const OneBox = styled.div<{ open: boolean }>`
	display: flex;
	justify-content: ${(props) => (props.open ? "left" : "center")};
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
`;

type Props = {
	open: boolean;
};

const Sidebar: FC<Props> = ({ open }) => {
	const list = useList();

	return (
		<Container open={open}>
			<OneBox open={open}>
				<MyUserAvatar />
			</OneBox>
			{list.map(({ id, isLine = false, icon, txt, onClick }) => {
				if (isLine) {
					return <Divider key={id} />;
				} else {
					return (
						<IconAndText
							testId={id}
							key={id}
							onClick={onClick!}
							open={open}
							icon={icon!}
							txt={txt!}
						/>
					);
				}
			})}
		</Container>
	);
};

export default Sidebar;
