import _ from "lodash";
import React, { FC } from "react";
import Panel from "../Panel";
import { UserErrorsType, UserType } from "../../types/userTypes";
import TextField from "../TextField";
import ColorPicker from "../ColorPicker";
import UserAvatar from "../UserAvatar";
import InlineEditText from "../InlineEditText";

type UserField = keyof UserType;

type Props = {
	isEdit: boolean;
	errors: UserErrorsType;
	value: UserType;
	onChange: (field: UserField, value: any) => void;
};

const getError = (errors: UserErrorsType, field: UserField): string => {
	const error = errors[field];
	if (error) {
		return error;
	} else {
		return "";
	}
};

const UserOne: FC<Props> = ({ isEdit, errors, value: user, onChange }) => {
	return (
		<Panel title="帳號">
			<UserAvatar
				style={{
					marginTop: 8,
					marginBottom: 8,
				}}
				color={user.color}
				value={user.display_name}
			/>
			{isEdit ? (
				<InlineEditText
					id="display_name"
					label="名字"
					placeholder="Name"
					error={getError(errors, "display_name")}
					defaultValue={user.display_name}
					onConfirm={(value) => onChange("display_name", value)}
				/>
			) : (
				<TextField
					id="display_name"
					label="名字"
					disabled={false}
					placeholder="Name"
					readOnly={false}
					error={getError(errors, "display_name")}
					autoFocus={false}
					defaultValue={user.display_name}
					onChange={(value) => onChange("display_name", value)}
				/>
			)}

			{isEdit ? (
				<InlineEditText
					id="email"
					type="email"
					label="Email"
					placeholder="username@astar.tw"
					error={getError(errors, "email")}
					defaultValue={user.email}
					onConfirm={(value) => onChange("email", value)}
				/>
			) : (
				<TextField
					id="email"
					type="email"
					label="Email"
					disabled={false}
					placeholder="username@astar.tw"
					readOnly={false}
					error={getError(errors, "email")}
					autoFocus={false}
					defaultValue={user.email}
					onChange={(value) => onChange("email", value)}
				/>
			)}

			{_.has(user, "password") && (
				<TextField
					id="password"
					type="password"
					disabled={false}
					label="密碼"
					placeholder="密碼"
					readOnly={false}
					error={getError(errors, "password")}
					autoFocus={false}
					defaultValue={user.password!}
					onChange={(value) => onChange("password", value)}
				/>
			)}
			<div>
				<div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
					代表色:
				</div>
				<ColorPicker
					color={user.color}
					onChange={(value) => {
						onChange("color", value);
					}}
				/>
			</div>
		</Panel>
	);
};

export default UserOne;
