import { useQuery } from "react-query";
import queryKeys from "./queryKeys";
import useAjax from "../hooks/useAjax";
import { ErrorType } from "../types/statusErrorTypes";

const prefix_url = "/me";

type MeType = {
	data: {
		id: string;
		email: string;
		display_name: string;
		color: string;
	};
};

export const useMe = () => {
	const ajax = useAjax();

	return useQuery<MeType, ErrorType>(queryKeys.ME, () => ajax.get(prefix_url));
};
