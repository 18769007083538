import React, { useState } from "react";
import { useNavigate } from "@reach/router";
import defaultValue from "./defaultData.json";
import { path as listPath } from "../../routes/UserListRoute";
import useButtonDisabled from "./useButtonDisabled";
import { UserErrorsType, UserType } from "../../types/userTypes";
import UserOne from "../UserOne";
import useSubmitMutation from "./useSubmitMutation";
import Button from "../Button";

const UserOneAdd = () => {
	const [user, setUser] = useState<UserType>(defaultValue as UserType);
	const [errors, setErrors] = useState<UserErrorsType>({});
	const navigate = useNavigate();
	const isDisabled = useButtonDisabled(errors);
	const addMutation = useSubmitMutation();

	return (
		<>
			<UserOne
				isEdit={false}
				errors={errors}
				value={user}
				onChange={(field, value) => {
					setUser((user) => {
						return { ...user, [field]: value };
					});

					setErrors((errors) => {
						return {
							...errors,
							[field]: "",
						};
					});
				}}
			/>
			<div>
				<Button
					style={{ marginRight: "1rem" }}
					disabled={isDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							await navigate(listPath);
						}
					}}
				>
					取消
				</Button>
				<Button
					disabled={isDisabled}
					onClick={() => {
						if (window.confirm("是否確定送出?")) {
							addMutation.mutate(user, {
								async onSuccess() {
									await navigate(listPath);
								},
								async onError(data) {
									setErrors(data.response.data.errors);
								},
							});
						}
					}}
				>
					送出
				</Button>
			</div>
		</>
	);
};

export default UserOneAdd;
