import React, { FC } from "react";
import { Grid, GridProps } from "@material-ui/core";

const GridItem: FC<GridProps> = ({ xs, children }) => {
	return (
		<Grid
			container
			item
			xs={xs}
			style={{ paddingTop: "1.5rem", paddingRight: "1rem" }}
		>
			{children}
		</Grid>
	);
};

export default GridItem;
