import { MajorErrorsType } from "../../types/majorTypes";
import { MajorField } from "./types";

const getError = (errors: MajorErrorsType, field: MajorField): string => {
	const error = errors[field];
	if (error) {
		return error;
	} else {
		return "";
	}
};

export default getError;
