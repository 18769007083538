import React, { FC, useState } from "react";
import columnMap from "./columnMap";
import categories, { CategoryIdType } from "../CategoryList/categories";
import AddIconTextButton from "../AddIconTextButton";
import AddDialog from "../AddDialog";
import PanelList from "../PanelList";
import { usePatchMutation } from "../../queries/resource.query";

type Props = {
	categoryId: CategoryIdType;
};

const CategoryOne: FC<Props> = ({ categoryId }) => {
	const updateSorting = usePatchMutation(`${categoryId}/priority`);
	const [open, setOpen] = useState(false);
	const columns = columnMap[categoryId];

	return (
		<div>
			<AddIconTextButton onClick={() => setOpen(true)} />
			<AddDialog
				title={`新增${
					categories.find((e) => e.id === categoryId)?.label || ""
				}`}
				resource={categoryId}
				open={open}
				columns={columns}
				onClose={() => {
					setOpen(false);
				}}
			/>
			<PanelList
				editColumn
				editDialog={false}
				resource={categoryId}
				columns={columns}
				onSort={(list) => {
					const options = list.map((e, priority) => {
						return { id: e.id, priority };
					});
					updateSorting.mutate(
						{ options },
						{
							onError() {
								window.alert("失敗，請聯絡管理員，或稍後再試。");
							},
						}
					);
				}}
			/>
		</div>
	);
};

export default CategoryOne;
