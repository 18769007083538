import { CategoryIdType } from "../CategoryList/categories";
import ColumnType from "../AddDialog/columnType";
import InputType from "../AddDialog/inputTypes";

type columnMapType = Record<string, ColumnType[]>;

const columnMap: columnMapType = {
	[CategoryIdType.qualification]: [
		{
			id: "short_name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "簡稱",
			autoFocus: true,
			placeholder: "MA",
			emptyError: "請輸入簡稱",
			isVisible: true,
		},
		{
			id: "full_name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "全名",
			autoFocus: false,
			placeholder: "Master of Arts",
			emptyError: "請輸入全名",
			isVisible: true,
		},
	],
	[CategoryIdType.level]: [
		{
			id: "name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "等級",
			autoFocus: true,
			placeholder: "",
			emptyError: "請輸入等級",
			isVisible: true,
		},
	],
	[CategoryIdType.fieldOfStudy]: [
		{
			id: "name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "領域",
			autoFocus: true,
			placeholder: "",
			emptyError: "請輸入領域",
			isVisible: true,
		},
	],
	[CategoryIdType.typeOfSchool]: [
		{
			id: "name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "學校類別",
			autoFocus: true,
			placeholder: "",
			emptyError: "請輸入學校類別",
			isVisible: true,
		},
	],
	[CategoryIdType.pace]: [
		{
			id: "name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "學制",
			autoFocus: true,
			placeholder: "",
			emptyError: "請輸入學制",
			isVisible: true,
		},
	],
	[CategoryIdType.duration]: [
		{
			id: "name",
			defaultValue: "",
			children: [],
			inputType: InputType.text,
			label: "期間",
			autoFocus: true,
			placeholder: "",
			emptyError: "請輸入期間",
			isVisible: true,
		},
	],
};

export default columnMap;
