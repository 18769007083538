import React, { FC } from "react";
import styled from "styled-components";
import RadioButton from "./RadioButton";

const Container = styled.div`
	width: 11.375rem;
	height: 2.625rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

export type Props = {
	id: string;
	className?: string;
	checked: any;
	onChange: (b: any) => void;
	label1: string;
	value1: any;
	label2: string;
	value2: any;
};

const RadioButtons: FC<Props> = ({
	id,
	className,
	checked,
	onChange,
	label1,
	value1,
	label2,
	value2,
}) => {
	return (
		<Container className={className} id={id}>
			<RadioButton
				checked={checked === value1}
				label={label1}
				onChange={() => {
					onChange(value1);
				}}
			/>
			<RadioButton
				checked={checked === value2}
				label={label2}
				onChange={() => {
					onChange(value2);
				}}
			/>
		</Container>
	);
};

export default RadioButtons;
