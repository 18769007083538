import _ from "lodash";
import useSearchObject from "./useSearchObject";

const defaultPage = 0;

function isNumericString(candidate: string): boolean {
	return candidate.trim() !== "" && Number.isFinite(Number(candidate));
}

const usePage = () => {
	const searchObj = useSearchObject();
	if (
		_.isEmpty(searchObj) ||
		!_.has(searchObj, "page") ||
		!isNumericString(searchObj.page)
	) {
		return defaultPage;
	} else {
		return parseInt(searchObj.page);
	}
};

export default usePage;
