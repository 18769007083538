import { UniversityField } from "./types";
import { UniversityErrorsType } from "../../types/universityType";

const getError = (
	errors: UniversityErrorsType,
	field: UniversityField
): string => {
	const error = errors[field];
	if (error) {
		return error;
	} else {
		return "";
	}
};

export default getError;
