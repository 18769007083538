import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "@reach/router";

const Container = styled(Link)`
	color: #4672e8;
	font-family: Roboto, serif;
	font-size: 0.85rem;
	display: flex;
	align-items: center;

	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const ClearAll = () => {
	const location = useLocation();
	return <Container to={`${location.pathname}`}>清除</Container>;
};

export default ClearAll;
