import styled from "styled-components";

type Props = {
	vertical?: boolean;
};

const FlexBox = styled.div<Props>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: ${(props) => (props.vertical ? "column" : "row")};
`;

export default FlexBox;
