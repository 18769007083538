import React, { FC } from "react";
import SelectField, { Props as SelectFieldProps } from "../SelectField";

export type CurrencyType = "" | "EUR" | "GBP" | "USD";

type Option = {
	id: CurrencyType;
	value: CurrencyType;
	label: string;
};

const currencyOptions: Option[] = [
	{
		id: "EUR",
		value: "EUR",
		label: "歐元",
	},
	{
		id: "GBP",
		value: "GBP",
		label: "英鎊",
	},
	{
		id: "USD",
		value: "USD",
		label: "美金",
	},
];

export interface Props
	extends Omit<
		SelectFieldProps,
		"value" | "defaultValue" | "onChange" | "defaultLabel" | "options"
	> {
	value?: CurrencyType;
	defaultValue?: CurrencyType;
	onChange: (currency: CurrencyType) => void;
}

const CurrencySelect: FC<Props> = ({
	id,
	disabled,
	error,
	value,
	defaultValue,
	onChange,
	className,
}) => {
	const selectFieldProps: SelectFieldProps = {
		id,
		disabled,
		options: currencyOptions,
		defaultLabel: "幣別",
		error,
		className,
		onChange(e) {
			onChange(e as CurrencyType);
		},
	};

	if (value !== undefined) {
		selectFieldProps.value = value;
	}
	if (defaultValue !== undefined) {
		selectFieldProps.defaultValue = defaultValue;
	}

	return <SelectField {...selectFieldProps} />;
};

export default CurrencySelect;
