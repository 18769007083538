import _ from "lodash";
import querystring from "query-string";
import useSearchObject from "../../hooks/useSearchObject";

function useResource() {
	const searchObject = useSearchObject();
	delete searchObject.page;
	delete searchObject.limit;

	if (_.isEmpty(searchObject)) {
		return "major";
	} else {
		return `major?${querystring.stringify(searchObject)}`;
	}
}

export default useResource;
