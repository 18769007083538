import _ from "lodash";
import { MajorOnePdfType } from "../../../types/majorTypes";

export function getEntryRequirementsContent(major: MajorOnePdfType): any {
	const ul = [];
	if (major.entry_gpa_value !== "") {
		ul.push(major.entry_gpa_value);
	}
	if (major.entry_tw_gpa !== 0) {
		ul.push(`GPA ${major.entry_tw_gpa}`);
	}
	if (major.entry_tw_percentile !== 0) {
		ul.push(`${major.entry_tw_percentile}%`);
	}
	if (major.any_background) {
		ul.push(`Relevant background required`);
	}
	if (major.allow_conditional_offer) {
		ul.push(`Conditional admission`);
	}

	if (major.open_seasons.length !== 0) {
		ul.push(
			`Start date ${major.open_seasons
				.map((e) => _.capitalize(e.toLocaleLowerCase()))
				.join(" / ")}`
		);
	}

	if (major.apply_deadline !== "") {
		ul.push(`Application deadline ${major.apply_deadline}`);
	}

	if (major.other_entry_requirement !== "") {
		ul.push(major.other_entry_requirement);
	}

	return {
		ul,
		style: "content",
	};
}
