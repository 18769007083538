import React, { FC } from "react";
import RadioButtons from "../RadioButtons";
import { Props as RadioButtonsProps } from "../RadioButtons";

interface Props
	extends Omit<
		RadioButtonsProps,
		"label1" | "value1" | "label2" | "value2" | "checked" | "onChange"
	> {
	checked: boolean;
	onChange: (b: boolean) => void;
}

const YesNoButton: FC<Props> = (props) => {
	return (
		<RadioButtons
			{...props}
			label1="是"
			value1={true}
			label2="否"
			value2={false}
		/>
	);
};

export default YesNoButton;
