import React, { FC, useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Button as MaterialButton } from "@material-ui/core";
import TextField from "../TextField";
import Button from "../Button";
import Loading from "../Loading";
import GoogleMap from "./GoogleMap";
import { GOOGLE_MAP_KEY } from "../../config";
import Dialog from "../Dialog";
import getShortCountry from "./getShortCountry";
import getLongCountry from "./getLongCountry";
import getShortCity from "./getShortCity";
import getLongCity from "./getLongCity";
import getLat from "./getLat";
import getLng from "./getLng";

export type PlaceType = {
	place_id: string;
	formatted_address: string;
	short_country: string;
	long_country: string;
	short_city: string;
	long_city: string;
	lat: number;
	lng: number;
};

type Props = {
	id: string;
	open: boolean;
	defaultKeyword: string;
	onConfirm: (place: PlaceType) => void;
	onClose: () => void;
};

const ChooseDialog: FC<Props> = ({
	id,
	open,
	defaultKeyword,
	onConfirm,
	onClose,
}) => {
	const [mapLoaded, setMapLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [index, setIndex] = useState(-1);
	const [keyword, setKeyword] = useState("");
	const [results, setResults] = useState<google.maps.GeocoderResult[]>();

	//clear after opening the dialog again
	useEffect(() => {
		if (open) {
			setKeyword(defaultKeyword);
			setIndex(-1);
			setResults(undefined);
		}
	}, [open, defaultKeyword]);

	useEffect(() => {
		if (!mapLoaded) {
			const loader = new Loader({
				apiKey: GOOGLE_MAP_KEY,
				version: "weekly",
				language: "en-GB",
			});

			loader.load().finally(() => {
				setMapLoaded(true);
			});
		}
	}, [mapLoaded]);

	if (!mapLoaded) {
		return <Loading />;
	}

	return (
		<Dialog
			id={`DIALOG_MAP_${id}`}
			open={open}
			title="選擇地點"
			onOk={() => {
				if (index !== -1 && results) {
					const result = results[index];

					onConfirm({
						place_id: result.place_id,
						formatted_address: result.formatted_address,
						short_country: getShortCountry(result),
						long_country: getLongCountry(result),
						short_city: getShortCity(result),
						long_city: getLongCity(result),
						lat: getLat(result),
						lng: getLng(result),
					});
				}
			}}
			onClose={onClose}
			disabledOk={
				index === -1 ||
				!results ||
				(index !== -1 &&
					results &&
					results.length !== 0 &&
					getLongCity(results[index]) === "") ||
				loading ||
				!mapLoaded ||
				keyword === ""
			}
			isLoading={loading}
			dialogActions={
				results && (
					<>
						<MaterialButton
							disabled={index <= 0}
							color="primary"
							onClick={() => {
								setIndex((index) => index - 1);
							}}
						>
							上一個
						</MaterialButton>
						<MaterialButton
							disabled={index + 1 >= results.length}
							color="primary"
							onClick={() => {
								setIndex((index) => index + 1);
							}}
						>
							下一個
						</MaterialButton>
					</>
				)
			}
		>
			<div style={{ display: "flex" }}>
				<div>
					<TextField
						id={`${id}_searchBox`}
						disabled={false}
						error=""
						autoFocus={true}
						readOnly={false}
						value={keyword}
						onChange={setKeyword}
					/>
				</div>
				<div style={{ marginLeft: 16 }}>
					<Button
						disabled={keyword === ""}
						onClick={() => {
							setIndex(-1);
							setLoading(true);

							const geoCoder = new google.maps.Geocoder();
							geoCoder
								.geocode({ address: keyword })
								.then(({ results = [] }) => {
									setResults(results);

									if (results.length !== 0) {
										setIndex(0);
									}
								})
								.finally(() => {
									setLoading(false);
								});
						}}
					>
						搜尋
					</Button>
				</div>
			</div>
			{loading && <Loading />}
			{results && results.length === 0 && <div>找不到這個位置</div>}
			{results && results.length > 0 && index !== -1 && (
				<GoogleMap result={results[index]} />
			)}
		</Dialog>
	);
};

export default ChooseDialog;
