import { MajorOnePdfType } from "../../../types/majorTypes";

export function getLocationInformation(major: MajorOnePdfType) {
	const arr = [
		major.country,
		major.city,
		major.address,
		`${major.pace} - ${major.duration}`,
	];

	if (major.work_placement) {
		arr.push("Work placement");
	}

	if (major.has_post_study_work_visa) {
		arr.push("Post-Study work visa");
	}

	return arr;
}
