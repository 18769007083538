import React from "react";
import { navigate } from "@reach/router";
import { CellType } from "../KodenTable/KodenTable";
import BinIcon from "../Binicon";
import { prefixPath } from "../../routes/UniversityOneEditRoute";
import { useRemoveMutation } from "../../queries/resource.query";
import { resource, useFindAllUniversity } from "./UniversityList";
import { UniversityRowType } from "../../types/universityType";
import UniversityLogo from "../UniversityLogo";
import EditIcon from "../EditIcon";

type UseCellsType = () => CellType[];

const useCells: UseCellsType = () => {
	const removeMutation = useRemoveMutation(resource);
	const findAll = useFindAllUniversity();

	return [
		{
			id: "university_name",
			align: "left",
			isEllipsis: true,
			style: { maxWidth: 150 },
			component: (obj: UniversityRowType) => {
				return obj.name;
			},
		},
		{
			id: "country",
			align: "left",
			isEllipsis: true,
			style: { maxWidth: 150 },
			component: (obj: UniversityRowType) => {
				return obj.country;
			},
		},
		{
			id: "city",
			align: "left",
			isEllipsis: true,
			style: { maxWidth: 150 },
			component: (obj: UniversityRowType) => {
				return obj.city;
			},
		},
		{
			id: "logo_cut",
			align: "left",
			isEllipsis: true,
			style: {
				maxWidth: 220,
			},
			component: ({ logo_cut }: UniversityRowType) => {
				return <UniversityLogo value={logo_cut} />;
			},
		},
		{
			id: "edit",
			align: "center",
			style: {
				width: 65,
			},
			isEllipsis: false,
			component: (university: UniversityRowType) => {
				return (
					<EditIcon
						onClick={async () => {
							await navigate(`${prefixPath}/${university.id}`);
						}}
					/>
				);
			},
		},
		{
			id: "delete",
			align: "center",
			style: {
				width: 65,
			},
			isEllipsis: false,
			component: (university: UniversityRowType) => {
				return (
					<BinIcon
						disabled={removeMutation.isLoading}
						onClick={() => {
							if (window.confirm(`確定要刪除${university.name}?`)) {
								removeMutation.mutate(
									{ id: university.id },
									{
										async onSuccess() {
											await findAll.refetch();
										},
									}
								);
							}
						}}
					/>
				);
			},
		},
	];
};

export default useCells;
