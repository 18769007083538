import _ from "lodash";
import ColumnType, { ChildrenType, ColumnIdType } from "./columnType";
import { StateMapType } from "../../hooks/useStateMap";

const emptyChildrenState = (
	map: StateMapType,
	id: ColumnIdType,
	columns: ColumnType[],
	children: ChildrenType
): StateMapType => {
	map = _.clone(map);
	const columnMap = _.mapKeys(columns, "id");

	children.forEach((columnId) => {
		map[columnId] = columnMap[columnId].defaultValue;
	});

	return map;
};

export default emptyChildrenState;
