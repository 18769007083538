import React, { useState } from "react";
import styled from "styled-components";
import { FaUserAlt } from "react-icons/fa";
import { IoMdKey } from "react-icons/io";
import { darken } from "@material-ui/core";
import FlexBox from "../FlexBox";
import { useLoginMutation } from "../../queries/auth.query";
import MyTextField from "./MyTextField";
import src from "./bg.png";
import { IPAD_BREAKPOINT } from "../../config";

const Container = styled(FlexBox)`
	width: 100%;
	min-height: 100vh;
	border: solid;
	overflow-x: hidden;
	background-image: url("${src}");
	background-size: cover;
`;

const LoginBox = styled(FlexBox)`
	width: 20rem;
	height: 450px;
	background-color: white;
	border-radius: 10px;

	.MuiFormControl-root:nth-of-type(2) {
		margin-top: 3rem;
		margin-bottom: 5rem;
	}

	@media only screen and (min-width: ${IPAD_BREAKPOINT}) {
		border-radius: 25px;
		width: 50rem;
		height: 37.5rem;

		.MuiFormControl-root {
			width: 25rem;
		}
	}
`;

const Title = styled.h3`
	font-family: var(--fontFamily);
	font-size: var(--bigFontSize);
	color: var(--blue2);
`;

const UserIcon = styled(FaUserAlt)`
	color: #848484;
`;

const PasswordIcon = styled(IoMdKey)`
	color: #848484;
`;

const Button = styled.button`
	width: 18.75rem;
	height: 2.5rem;
	font-size: 0.85rem;
	background-color: var(--blue3);
	border: none;
	outline: none;
	border-radius: 4px;
	color: white;
	font-weight: bold;
	cursor: pointer;
	transition: all 0.25s;

	&:hover {
		background-color: ${darken("rgb(108, 135, 198)", 0.15)};
	}

	&[disabled] {
		background-color: var(--grey);
		cursor: not-allowed;
	}
`;

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const loginMutation = useLoginMutation();
	const onLogin = () => loginMutation.mutate({ email, password });

	return (
		<Container>
			<LoginBox>
				<FlexBox
					vertical
					onKeyUp={(e) => {
						if (e.keyCode === 13) {
							onLogin();
						}
					}}
				>
					<Title>STAR SYSTEM</Title>
					<MyTextField
						id="email"
						testId="email"
						type="email"
						icon={UserIcon}
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<MyTextField
						id="password"
						testId="password"
						type="password"
						icon={PasswordIcon}
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Button
						id="btn_submit"
						data-test-id="btn_submit"
						disabled={loginMutation.isLoading}
						onClick={onLogin}
					>
						{loginMutation.isLoading ? "Login..." : "Login"}
					</Button>
				</FlexBox>
			</LoginBox>
		</Container>
	);
};

export default Login;
