import React, { forwardRef } from "react";
import { useFindAll } from "../../queries/resource.query";
import { UniversityRowType } from "../../types/universityType";
import RemoteSelectField from "../RemoteSelectField";

type Props = {
	id: string;
	value: string;
	errors: string;
	onChange: (value: UniversityRowType | null) => void;
};

const resource = "university/list";

export const useFindAllUniversity = () => {
	return useFindAll<() => void, UniversityRowType[]>(resource);
};

const UniversitySelect = forwardRef(
	({ id, value, errors, onChange }: Props, ref) => {
		const findAll = useFindAllUniversity();

		return (
			<RemoteSelectField
				ref={ref}
				id={id}
				url={resource}
				defaultLabel="請選擇"
				error={errors}
				disabled={false}
				value={value}
				onChange={(universityId) => {
					if (findAll.data) {
						const university = findAll.data.find((e) => e.id === universityId);
						if (university) {
							onChange(university);
						} else {
							onChange(null);
						}
					}
				}}
			/>
		);
	}
);

export default UniversitySelect;
