import React, { FC, Fragment } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getFindAllKey, useFindAll } from "../../queries/resource.query";
import _Panel from "../Panel";
import PanelListRow from "../PanelListRow";
import { OptionResponseType } from "../../types/optionTypes";
import useSearchObject from "../../hooks/useSearchObject";
import ColumnType from "../AddDialog/columnType";
import { useQueryClient } from "react-query";

type Props = {
	resource: string;
	columns: ColumnType[];
	editColumn: boolean;
	editDialog: boolean;
	onEditClick?: (id: string) => void;
	onSort?: (options: any[]) => void;
};

export const Panel = styled(_Panel)`
	margin-top: 1rem;
	max-width: 36.5rem;
`;

export const HeaderColumn = styled.div`
	width: var(--panel-column-width);

	&:nth-of-type(2) {
		margin-left: 2rem;
	}
`;

export const useResourceAll = (resource: string) => {
	const searchObject = useSearchObject();
	return useFindAll<() => void, OptionResponseType[]>(resource, searchObject);
};

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const PanelList: FC<Props> = ({
	resource,
	editColumn,
	editDialog,
	columns,
	onEditClick,
	onSort,
}) => {
	const searchObject = useSearchObject();
	const queryKey = getFindAllKey(resource, searchObject);
	const queryClient = useQueryClient();
	const { isLoading, data } = useResourceAll(resource);

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<Panel
			title={
				<>
					{columns
						.filter((e) => e.isVisible)
						.map((column) => {
							if (column.isVisible) {
								return (
									<HeaderColumn key={column.id}>{column.label}</HeaderColumn>
								);
							} else {
								return <Fragment />;
							}
						})}
				</>
			}
		>
			{/*@ts-ignore*/}
			<DragDropContext
				onDragEnd={({ source, destination }, provided) => {
					if (destination) {
						const sourceRecord = data![source.index];
						const destinationRecord = data![destination.index];
						if (sourceRecord.id !== destinationRecord.id) {
							queryClient.setQueryData(queryKey, (data) => {
								const reorderedData = reorder(
									data as any[],
									source.index,
									destination.index
								);
								if (onSort !== undefined) {
									onSort(reorderedData);
								}
								return reorderedData;
							});
						}
					}
				}}
			>
				{/*@ts-ignore*/}
				<Droppable droppableId="droppable">
					{(droppableProvided) => (
						<div ref={droppableProvided.innerRef}>
							{data?.map((option, index) => {
								const key = `item-${option.id}`;

								return (
									//@ts-ignore
									<Draggable key={key} draggableId={key} index={index}>
										{(draggableProvided) => (
											<div
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												{...draggableProvided.dragHandleProps}
											>
												<div
													style={{
														display: "grid",
														gridTemplateColumns: "1fr 1fr",
													}}
												>
													<PanelListRow
														key={option.id}
														editColumn={editColumn}
														editDialog={editDialog}
														resource={resource}
														columns={columns}
														option={option}
														onEditClick={(recordId) => {
															if (onEditClick) {
																onEditClick(recordId);
															}
														}}
													/>
													<div
														style={{ display: "flex", alignItems: "center" }}
													>
														<DragHandleIcon />
													</div>
												</div>
											</div>
										)}
									</Draggable>
								);
							})}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Panel>
	);
};

export default PanelList;
