import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { GiHamburgerMenu } from "react-icons/gi";
import useIsWeb from "../../hooks/useIsWeb";
import { APP_NAME } from "../../config";
import { path as homeUrl } from "../../routes/HomeRoute";

const Container = styled.header`
	position: sticky;
	top: 0;
	z-index: 10;
	height: var(--header-height);

	background-color: var(--blue2);
	color: #f7f7f7;
	display: flex;
	align-items: center;
`;

const Box = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-right: 2rem;
`;

const Left = styled.div`
	display: flex;
	align-items: center;
	height: 25px;
`;

const LinkBox = styled(Link)`
	display: flex;
	font-size: 1rem;
	color: #f7f7f7;
	text-decoration: none;
`;

const Title = styled.div`
	margin-left: 0.5rem;
	font-size: 30px;
	font-weight: bold;
	color: var(--white);
	font-family: var(--fontFamily);
`;

const DrawerIcon = styled(GiHamburgerMenu)`
	height: 100%;
	width: 5rem;
	user-select: none;
	cursor: pointer;
`;

type HeaderProps = {
	onHamburgerClick: () => void;
};

const Header: FC<HeaderProps> = ({ onHamburgerClick }) => {
	const isWeb = useIsWeb();

	return (
		<Container>
			<Box>
				<Left>
					<DrawerIcon data-test-id="drawer" onClick={onHamburgerClick} />
					<LinkBox to={homeUrl} title={APP_NAME}>
						{isWeb && <Title>{APP_NAME}</Title>}
					</LinkBox>
				</Left>
			</Box>
		</Container>
	);
};

export default Header;
