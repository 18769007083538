import { useNavigate } from "@reach/router";
import { path as homePath, title as homeTitle } from "../../routes/HomeRoute";
import {
	path as userListPath,
	title as userListTitle,
} from "../../routes/UserListRoute";
import {
	path as uniPath,
	title as uniTitle,
} from "../../routes/UniversityListRoute";
import {
	path as categoryPath,
	title as categoryTitle,
} from "../../routes/CategoryListRoute";
import accountSvg from "./icons/account.svg";
import uniSvg from "./icons/uni.svg";
import categorySvg from "./icons/category.svg";
import logoutSvg from "./icons/logout.svg";
import dashboardSvg from "./icons/dashboard.svg";
import useLogout from "../../hooks/useLogout";

type ObjType1 = {
	id: string;
	href?: string;
	txt?: string;
	icon?: string;
	isLine: boolean;
	onClick?: () => Promise<void>;
};

type ReturnType = () => ObjType1[];

const useList: ReturnType = () => {
	const navigate = useNavigate();
	const logout = useLogout();

	return [
		{
			id: "link_home",
			href: homePath,
			txt: homeTitle,
			icon: dashboardSvg,
			isLine: false,
			async onClick() {
				await navigate(homePath);
			},
		},
		{
			id: "userListPath",
			href: userListPath,
			txt: userListTitle,
			icon: accountSvg,
			isLine: false,
			async onClick() {
				await navigate(userListPath);
			},
		},
		{
			id: "link_uni",
			href: uniPath,
			txt: uniTitle,
			icon: uniSvg,
			isLine: false,
			async onClick() {
				await navigate(uniPath);
			},
		},
		{
			id: "link_category",
			href: categoryPath,
			txt: categoryTitle,
			icon: categorySvg,
			isLine: false,
			async onClick() {
				await navigate(categoryPath);
			},
		},
		{ id: "link_is_line", isLine: true },
		{
			id: "link_logout",
			isLine: false,
			txt: "登出",
			icon: logoutSvg,
			async onClick() {
				if (window.confirm("確定要登出嗎?")) {
					await logout();
				}
			},
		},
	];
};

export default useList;
