import React, { FC } from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { useNavigate } from "@reach/router";
import categories from "./categories";
import { path } from "../../routes/CategoryListRoute";

const Container = styled.div`
	max-width: 530px;
	min-height: 200px;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
`;

const Block = styled(Paper)`
	width: 166px;
	height: 67px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;

	div {
		color: var(--grey);
		font-size: 14px;
	}
`;

const CategoryList: FC = () => {
	const navigate = useNavigate();

	return (
		<Container>
			{categories.map(({ id, label, engLabel, value }) => (
				<Block
					key={id}
					elevation={3}
					title={label}
					onClick={async () => {
						await navigate(`${path}/${id}`);
					}}
				>
					<div>{label}</div>
					<div>{engLabel}</div>
				</Block>
			))}
		</Container>
	);
};

export default CategoryList;
