import { HeaderType } from "../KodenTable/KodenTable";

const useHeaders: () => HeaderType[] = () => {
	return [
		{
			id: "university_name",
			label: "學校名稱",
			align: "left",
			style: { width: 150 },
		},
		{ id: "country", label: "國家", align: "left", style: { width: 150 } },
		{ id: "city", label: "城市", align: "left", style: { width: 150 } },
		{ id: "logo_cut", label: "學校圖片", align: "left", style: { width: 220 } },
		{
			id: "edit",
			label: "編輯",
			align: "center",
		},
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
	];
};

export default useHeaders;
