import React, { CSSProperties, forwardRef, Fragment } from "react";
import SelectField, { Props as SelectFieldProps } from "../SelectField";
import { useFindAll } from "../../queries/resource.query";
import { OptionType } from "../SelectField/SelectField";
import { ResourceType } from "../../types/categoryTypes";

type Props = {
	id: string;
	url: string;
	defaultLabel: string;
	error: string;
	value?: string;
	label?: string;
	style?: CSSProperties;
	defaultValue?: string;
	disabled: boolean;
	onChange: (value: string) => void;
	optionLabel?: string;
	optionValue?: string;
};

const RemoteSelectField = forwardRef(
	(
		{
			id,
			url,
			label,
			style,
			defaultLabel,
			error,
			value,
			defaultValue,
			disabled,
			onChange,
			optionLabel = "name",
			optionValue = "id",
		}: Props,
		ref
	) => {
		const findAll = useFindAll<() => void, ResourceType[]>(url);
		let options: OptionType[] = [];
		if (!findAll.isLoading && findAll.data) {
			options = findAll.data.map((e) => {
				const label = e[optionLabel];
				const value = e[optionValue];

				return {
					id: e.id ? e.id.toString() : value,
					label,
					value,
				};
			}) as OptionType[];
		}

		const selectFieldProps: SelectFieldProps = {
			id,
			disabled: findAll.isLoading || disabled,
			options,
			style,
			defaultLabel,
			error,
			onChange,
			label,
		};

		if (!findAll.isLoading) {
			if (value !== undefined) {
				selectFieldProps.value = value;
			}
			if (defaultValue !== undefined) {
				selectFieldProps.defaultValue = defaultValue;
			}
		}

		if (findAll.isLoading) {
			return <Fragment />;
		} else {
			return <SelectField {...selectFieldProps} ref={ref} />;
		}
	}
);

export default RemoteSelectField;
