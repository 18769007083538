import { v4 as uuidv4 } from "uuid";
import React, { FC } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import _DottedBox from "./DottedBox";
import { ErrorType } from "../../types/statusErrorTypes";
import imageExtensions from "./imageExtensions";
import videoExtensions from "./videoExtensions";
import useFileReader from "../../hooks/useFileReader";

const getExtension = (filename: string) => {
	const ext = (/[^./\\]*$/.exec(filename) || [""])[0];
	return ext.toLowerCase();
};

export const isImageFileName = (fileName: string) => {
	return imageExtensions.indexOf(getExtension(fileName)) !== -1;
};

export const isVideoFileName = (fileName: string) => {
	return videoExtensions.indexOf(getExtension(fileName)) !== -1;
};

const UploadText = styled.div`
	color: #434242;
	font-size: 2.5rem;
`;

const DottedBox = styled(_DottedBox)`
	margin: 5px;
`;

type Props = {
	multiple: boolean;
	image: boolean;
	video: boolean;
	maxInMB: number;
	onFileTooBig: (obj: { id: string; file: File }) => void;
	onFileUploadReady: (obj: { id: string }) => void;
	onFileUploading: (obj: { id: string; percentCompleted: number }) => void;
	onFileUploaded: (obj: { id: string; src: string }) => void;
	onFileUploadFailed: (obj: { id: string; err: ErrorType }) => void;
};

const getAccept = (image: boolean, video: boolean): string[] => {
	let accept: string[] = [];
	if (image) {
		accept = accept.concat(imageExtensions.map((e) => `.${e}`));
	}
	if (video) {
		accept = accept.concat(videoExtensions.map((e) => `.${e}`));
	}
	return accept;
};

const UploadButton: FC<Props> = ({
	multiple,
	image,
	video,
	maxInMB,
	onFileTooBig,
	onFileUploadReady,
	onFileUploading,
	onFileUploaded,
	onFileUploadFailed,
}) => {
	const getFileReader = useFileReader();
	const accept = getAccept(image, video);
	const getFileSizeInMB = (file: File) => {
		return file.size / 1024 / 1024;
	};

	const onDrop = (files: File[]) => {
		const fileWithIds = files.map((file) => {
			return { file, id: uuidv4() };
		});

		fileWithIds.forEach(async ({ id, file }) => {
			if (getFileSizeInMB(file) > maxInMB) {
				onFileTooBig({ id, file });
			} else {
				onFileUploadReady({ id });
				try {
					const binary = (await getFileReader(file, (event) => {
						const percentCompleted = Math.round(
							(event.loaded * 100) / event.total
						);
						onFileUploading({ id, percentCompleted });
					})) as string;

					onFileUploaded({ id, src: binary });
				} catch (e) {
					onFileUploadFailed({ id, err: e });
				}
			}
		});
	};
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		multiple,
	});

	return (
		<DottedBox {...getRootProps()}>
			<input {...getInputProps()} />
			<UploadText>+</UploadText>
		</DottedBox>
	);
};

export default UploadButton;
