import React, { FC } from "react";
import styled from "styled-components";
import BarWithLabel from "../BarWithLabel";

const Container = styled.label`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ComponentWrapper = styled.div`
	margin-top: 5px;
	margin-left: 10px;
`;

type Props = {
	text: string;
};

const BarWithLabelForField: FC<Props> = ({ text, children }) => {
	return (
		<Container>
			<BarWithLabel text={text} />
			<ComponentWrapper>{children}</ComponentWrapper>
		</Container>
	);
};

export default BarWithLabelForField;
