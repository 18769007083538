import React, { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import scrollIntoView from "scroll-into-view-if-needed";
import FlexBox from "../FlexBox";
import { OptionType } from "../SelectField/SelectField";

const Container = styled(FlexBox)`
	width: fit-content;
	height: 38px;
	font-size: 16px;
	color: #818080;
	font-weight: bold;
	border-radius: 25px;
	background-color: #ffffff;
	user-select: none;
	cursor: pointer;
	position: relative;

	display: flex;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;

	&:before {
		content: "";
		width: 10px;
		height: 10px;
	}
`;

const ArrowDown = styled.div`
	border: solid #818080;
	border-width: 0 3px 3px 0;
	padding: 3px;
	margin-left: 10px;
	margin-bottom: 5px;
	transform: rotate(45deg);
`;

const OptionContainer = styled.div`
	max-height: 45vh;
	overflow-y: auto;
	border-radius: 10px;
	border: solid 1px #c1c1c1;
	background-color: white;
	margin-top: 0.5rem;
	cursor: pointer;
	user-select: none;
`;

const Option = styled.div<{ select: boolean }>`
	height: 1rem;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: left;
	color: #818080;
	background-color: white;

	${(props) =>
		props.select &&
		css`
			background-color: #f2f2f2;
		`};

	&:hover {
		background-color: #f2f2f2;
	}

	&:first-of-type {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	&:last-of-type {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`;

type Props = {
	options: OptionType[];
	value: string;
	onChange: (id: string) => void;
};

const KodenSelect: FC<Props> = ({
	options = [],
	value,
	onChange = () => {},
}) => {
	const ref = React.useRef<HTMLDivElement | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const open = Boolean(anchorEl);
	const id = open ? "transitions-popper" : undefined;

	const close = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (open) {
			window.setTimeout(() => {
				if (ref.current != null) {
					const selectedElement = ref.current?.querySelector(".selected");

					if (selectedElement) {
						scrollIntoView(selectedElement, {
							block: "center",
						});
					}
				}
			});
		}
	}, [open]);

	return (
		<>
			<Container aria-describedby={id} onClick={handleClick}>
				<div>{options.find((e) => e.value === value)?.label}</div>
				<ArrowDown />
			</Container>
			<Popper id={id} open={open} anchorEl={anchorEl} transition>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={close}>
						<Fade {...TransitionProps} timeout={350}>
							<OptionContainer ref={ref}>
								{options.map(({ value: optionValue, label }) => {
									const select = optionValue === value;
									return (
										<Option
											key={optionValue}
											className={select ? "selected" : ""}
											select={select}
											onClick={() => {
												onChange(optionValue);
												close();
											}}
										>
											{label}
										</Option>
									);
								})}
							</OptionContainer>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};

export default KodenSelect;
