import React, { FC } from "react";
import { Link as RouterLink, navigate } from "@reach/router";
import styled from "styled-components";

const Link = styled(RouterLink)`
	color: black;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&:first-of-type {
		margin-right: 0.5rem;
	}
`;

type Props = {
	preTitle: string;
	preLink: string;
	title: string;
};

const Breadcrumbs: FC<Props> = ({ preTitle, preLink, title }) => {
	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			<Link
				to={preLink}
				onClick={async (e) => {
					e.preventDefault();
					await navigate(preLink);
				}}
			>
				{preTitle}
			</Link>
			<div>/</div>
			<span style={{ marginLeft: ".5rem" }}>{title}</span>
		</div>
	);
};

export default Breadcrumbs;
