import React, { FC } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import {
	path as categoryPath,
	title as categoryTitle,
} from "./CategoryListRoute";
import MainLayout from "../components/MainLayout";
import LayoutContent from "../components/MainLayout/LayoutContent";
import categories from "../components/CategoryList/categories";
import CategoryOne from "../components/CategoryOne/CategoryOne";
import Breadcrumbs from "../components/Breadcrumbs";

export const path = "/category/:categoryId";

const CategoryOneRoute: FC = (ignored: RouteComponentProps) => {
	const { categoryId } = useParams();
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={categoryTitle}
							preLink={categoryPath}
							title={`${
								categories.find((e) => e.id === categoryId)?.label
							}管理`}
						/>
					}
				>
					<CategoryOne categoryId={categoryId} />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default CategoryOneRoute;
