import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as homePath, title as homeTitle } from "./HomeRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import { useFindOne } from "../queries/resource.query";
import { MajorScreenshotType } from "../types/majorTypes";
import MajorOneEditScreenshot from "../components/MajorOneEditScreenshot";

export const prefixPath = "/major";
export const path = `${prefixPath}/:id/screenshot`;

const resource = "major";

export const useGetResource = () => {
	const { id } = useParams();
	return useFindOne<MajorScreenshotType>(resource, `${id}/screenshot`);
};

export const useMajorScreenshot = () => {
	const { id } = useParams();
	return useFindOne<MajorScreenshotType>(resource, `${id}/screenshot`);
};

const MajorOneEditScreenshotRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();
	const majorScreenshot = useMajorScreenshot();

	if (!isLogin) {
		return <LoginRoute />;
	} else if (majorScreenshot.isLoading) {
		return <Fragment />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={homeTitle}
							preLink={homePath}
							title={majorScreenshot.data!.name}
						/>
					}
				>
					<MajorOneEditScreenshot />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default MajorOneEditScreenshotRoute;
