import React, { FC } from "react";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
} from "@material-ui/core";

export type OpenSeasonValueType = "SPRING" | "AUTUMN";
export type OpenSeasonLabelType = "春季" | "秋季";

type Option = {
	id: OpenSeasonValueType;
	value: OpenSeasonValueType;
	label: OpenSeasonLabelType;
};

const options: Option[] = [
	{
		id: "SPRING",
		value: "SPRING",
		label: "春季",
	},
	{
		id: "AUTUMN",
		value: "AUTUMN",
		label: "秋季",
	},
];

type Props = {
	id: string;
	disabled: boolean;
	error: string;
	value?: OpenSeasonValueType[];
	defaultValue?: OpenSeasonValueType[];
	onChange: (value: OpenSeasonValueType[]) => void;
};

const OpeningSeasonSelect: FC<Props> = ({
	id,
	disabled,
	error,
	value,
	defaultValue,
	onChange,
}) => {
	return (
		<FormControl
			id={id}
			component="fieldset"
			disabled={disabled}
			style={{ display: "flex", flexDirection: "row" }}
		>
			{options.map((option) => {
				return (
					<FormControlLabel
						key={option.id}
						label={option.label}
						control={
							<Checkbox
								checked={value?.indexOf(option.value) !== -1}
								name={option.value}
								onChange={(_, checked) => {
									if (checked) {
										if (value) {
											onChange([...value, option.id]);
										}
										if (defaultValue) {
											onChange([...defaultValue, option.id]);
										}
									} else {
										if (value) {
											value.splice(value.indexOf(option.id), 1);
											onChange(value);
										}
										if (defaultValue) {
											defaultValue.splice(defaultValue.indexOf(option.id), 1);
											onChange(defaultValue);
										}
									}
								}}
							/>
						}
					/>
				);
			})}
			<FormHelperText error={error !== ""}>{error}</FormHelperText>
		</FormControl>
	);
};

export default OpeningSeasonSelect;
