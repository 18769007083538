import useSubmitMutation from "./useSubmitMutation";
import { UserErrorsType } from "../../types/userTypes";

type Type = (errors: UserErrorsType) => boolean;

const useButtonDisabled: Type = (errors) => {
	const addMutation = useSubmitMutation();
	const submit = useSubmitMutation();
	const hasError = !!Object.values(errors).find((e) => e !== "");

	return addMutation.isLoading || hasError || submit.isLoading;
};

export default useButtonDisabled;
