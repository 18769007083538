import React, { FC } from "react";
import styled from "styled-components";
import { SwatchesPicker } from "react-color";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

type Props = {
	color: string;
	onChange: (color: string) => void;
};

const ColorPicker: FC<Props> = ({ color, onChange }) => {
	return (
		<Container>
			{/*@ts-ignore*/}
			<SwatchesPicker
				color={color}
				onChangeComplete={(color) => {
					onChange(color.hex);
				}}
			/>
		</Container>
	);
};

ColorPicker.propTypes = {};

export default ColorPicker;
