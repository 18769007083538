type Type = {
	hasError: boolean;
	isLoading: boolean;
	dirty: boolean;
};

const getDisabledOk = ({ hasError, isLoading, dirty }: Type) => {
	if (isLoading) {
		return true;
	} else if (!dirty) {
		return false;
	} else {
		return hasError;
	}
};

export default getDisabledOk;
