import _ from "lodash";
import React, { useRef, useState } from "react";
import { useNavigate } from "@reach/router";
import MajorOne from "../MajorOne";
import defaultValue from "./defaultData.json";
import { MajorErrorsType, MajorType } from "../../types/majorTypes";
import { path as homePath } from "../../routes/HomeRoute";
import useButtonDisabled from "./useButtonDisabled";
import useSubmitMajorMutation from "../MajorOne/useSubmitMajorMutation";
import { useFindAllUniversity } from "../UniversitySelect/UniversitySelect";
import Button from "../Button";

const MajorOneAdd = () => {
	const navigate = useNavigate();
	const findAllUniversity = useFindAllUniversity();
	const majorRef = useRef<MajorType>(defaultValue as MajorType);
	const [errors, setErrors] = useState<MajorErrorsType>({});
	const isDisabled = useButtonDisabled(errors);
	const addMutation = useSubmitMajorMutation();

	return (
		<>
			<MajorOne
				isEdit={false}
				errors={errors}
				defaultValue={majorRef.current as MajorType}
				onChange={_.debounce((field, value) => {
					const toMajor = { ...majorRef.current, [field]: value };

					if (field === "university_id" && !findAllUniversity.isLoading) {
						const universityOption = findAllUniversity.data?.find(
							(e) => e.id === value
						);
						if (universityOption) {
							toMajor.ranking_qs = universityOption.ranking_qs;
							toMajor.ranking_complete_guide =
								universityOption.ranking_complete_guide;
							toMajor.ranking_guardian = universityOption.ranking_guardian;
							toMajor.ranking_us_news = universityOption.ranking_us_news;

							toMajor.address = universityOption.address;
							toMajor.address = universityOption.address;
							toMajor.address = universityOption.address;

							toMajor.country = universityOption.country;
							toMajor.city = universityOption.city;
							toMajor.address = universityOption.address;
						}
					}

					majorRef.current = toMajor;
					setErrors((errors) => {
						return {
							...errors,
							[field]: "",
						};
					});
				}, 200)}
			/>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					style={{ marginRight: "1rem" }}
					disabled={isDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							await navigate(homePath);
						}
					}}
				>
					取消
				</Button>
				<Button
					disabled={isDisabled}
					onClick={() => {
						if (window.confirm("是否確定送出?")) {
							addMutation.mutate(majorRef.current, {
								async onSuccess() {
									window.location.href = homePath;
								},
								async onError(data) {
									const errors = data.response.data.errors;
									setErrors(errors);
									if (!_.isEmpty(errors)) {
										window.alert(_.values(errors).join("\n").toString());
									}
								},
							});
						}
					}}
				>
					送出
				</Button>
			</div>
		</>
	);
};

export default MajorOneAdd;
