import _ from "lodash";
import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import MaterialAvatar from "@material-ui/core/Avatar";

const Avatar = styled(MaterialAvatar)`
	background-color: ${(props) => props.color};
`;

const getAvatarName = (value: string) => {
	if (_.isEmpty(value)) {
		return "A";
	}
	return value[0];
};

type Props = {
	style?: CSSProperties;
	className?: string;
	value: string;
	color: string;
};

const UserAvatar: FC<Props> = ({ style, className, value, color }) => {
	const avatarName = getAvatarName(value);

	return (
		<Avatar style={style} className={className} color={color}>
			{avatarName}
		</Avatar>
	);
};

export default UserAvatar;
