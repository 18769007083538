import getCity from "./getCity";

function getShortCity(result: google.maps.GeocoderResult): string {
	const city = getCity(result);
	if (city) {
		return city.short_name;
	} else {
		return "";
	}
}

export default getShortCity;
