import React, { FC, ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
	min-height: 100vh;
	padding: 2rem;
	margin-bottom: 35px;
`;

const Title = styled.h3`
	font-family: var(--fontFamily);
	margin: 0 0 1rem;
`;

type Props = {
	title: string | ReactElement;
	"data-test-id": string;
};

const ComponentContainer = styled.div`
	width: calc(768px - var(--sidebar-width));

	@media (min-width: 1024px) {
		width: calc(1024px - var(--sidebar-width));
	}

	@media (min-width: 1366px) {
		width: calc(1366px - var(--sidebar-width));
	}
`;

const LayoutContent: FC<Props> = ({ title, children, ...props }) => {
	return (
		<Container data-test-id={props["data-test-id"]}>
			<Title>{title}</Title>
			<ComponentContainer>{children}</ComponentContainer>
		</Container>
	);
};

export default LayoutContent;
