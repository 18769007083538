import React, { FC } from "react";
import KodenSelect from "../KodenSelect";
import { useFindAll } from "../../queries/resource.query";
import { CountryType } from "../../types/countryType";

type Props = {
	resource: string;
	value: string;
	onChange: (s: string) => void;
};

const CountrySelect: FC<Props> = ({ resource, value = "", onChange }) => {
	const findAll = useFindAll<unknown, CountryType[]>(`${resource}/country`);
	let options = [{ id: "", value: "", label: "國家" }];

	if (findAll.isFetched) {
		const data = findAll.data!.map((e) => {
			return {
				id: e.name,
				label: e.name,
				value: e.name,
			};
		});
		options = options.concat(data);
	}
	return <KodenSelect options={options} value={value} onChange={onChange} />;
};

export default CountrySelect;
