import { useContext } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../config";
import AccessTokenContext from "../contexts/AccessTokenContext";
import RefreshTokenContext from "../contexts/RefreshTokenContext";
import utils from "../utils";

type OnRequestType = (
	config: AxiosRequestConfig
) => Promise<AxiosRequestConfig>;

let isRefreshingToken = false;
let newAccessToken: null | string = null;

export const getAuthorizationHeader = (token: string) => {
	return {
		Authorization: `Bearer ${token}`,
	};
};

export const createAjax = () => {
	return axios.create({ baseURL: API_URL });
};

const getNewAccessToken = async (
	refreshToken: string | null
): Promise<string> => {
	isRefreshingToken = true;
	newAccessToken = null;

	const failedToRefresh = async () => {
		return Promise.reject("Failed to get a new access token");
	};

	if (!refreshToken) {
		isRefreshingToken = false;
		newAccessToken = null;
		return failedToRefresh();
	} else {
		return createAjax()
			.post(
				"/auth/refresh_token",
				{},
				{ headers: getAuthorizationHeader(refreshToken) }
			)
			.then((response) => {
				const accessToken = response.data?.access_token;
				newAccessToken = accessToken;
				return accessToken;
			})
			.catch(failedToRefresh)
			.finally(() => {
				isRefreshingToken = false;
			});
	}
};

const useOnRequest = () => {
	const { accessToken, setAccessToken } = useContext(AccessTokenContext);
	const { refreshToken, deleteRefreshToken } = useContext(RefreshTokenContext);

	const onRequest: OnRequestType = async (config) => {
		if (accessToken === null) {
			if (refreshToken == null) {
				utils.redirectToLoginPage();
				return config;
			} else {
				try {
					if (!isRefreshingToken) {
						const newAccessToken = await getNewAccessToken(refreshToken);
						setAccessToken(newAccessToken);
						config.headers = getAuthorizationHeader(newAccessToken);
						return config;
					} else {
						return new Promise((done) => {
							const timer = window.setInterval(() => {
								if (!isRefreshingToken && newAccessToken) {
									config.headers = getAuthorizationHeader(newAccessToken);
									window.clearInterval(timer);
									done(config);
								}
							}, 100);
						});
					}
				} catch (e) {
					setAccessToken(null);
					deleteRefreshToken();
					return config;
				}
			}
		} else {
			config.headers = getAuthorizationHeader(accessToken);
			return config;
		}
	};
	return onRequest;
};

const useOnResponseReject = () => {
	const { setAccessToken } = useContext(AccessTokenContext);
	const { refreshToken, deleteRefreshToken } = useContext(RefreshTokenContext);

	return async (error: any) => {
		return new Promise((resolve, reject) => {
			const errorConfig = error?.config;
			const status: number = error?.response?.status;
			const errorCode: number = error?.response?.data?.errorCode;

			if (status === 401 && errorCode === 10010 && !errorConfig._retry) {
				errorConfig._retry = true;
				getNewAccessToken(refreshToken)
					.then((newAccessToken) => {
						errorConfig.headers = getAuthorizationHeader(newAccessToken);
						setAccessToken((ignored) => newAccessToken);
						createAjax().request(errorConfig).then(resolve).catch(reject);
					})
					.catch((e) => {
						setAccessToken(null);
						deleteRefreshToken();
						reject(e);
					});
			} else {
				reject(error);
			}
		});
	};
};

const useAjax = () => {
	const ajax = createAjax();
	const onRequest = useOnRequest();
	const onResponseReject = useOnResponseReject();

	ajax.interceptors.request.use(onRequest, Promise.reject);
	ajax.interceptors.response.use((response) => response, onResponseReject);

	return ajax;
};

export default useAjax;
