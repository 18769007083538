import React, { FC, Fragment } from "react";
import { useMajorScreenshot } from "../../routes/MajorOneEditScreenshotRoute";
import styled from "styled-components";
import Button from "../Button";
import { useAddMutation, usePatchMutation } from "../../queries/resource.query";
import { useParams } from "@reach/router";
import Utils from "../../utils";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Base64Img: FC<{ src: string }> = ({ src }) => {
	return <img src={`data:image/png;base64,${src}`} alt="" />;
};

const MajorOneEditScreenshot: FC = () => {
	const { id } = useParams();
	const majorScreenshot = useMajorScreenshot();
	const updateScreenshot = useAddMutation(`major/${id}/screenshot`);
	const updateLatestScreenshot = usePatchMutation(`major/${id}/screenshot`);

	if (majorScreenshot.isLoading) {
		return <Fragment />;
	}

	const screenshot = majorScreenshot.data!;

	return (
		<Container>
			<div style={{ marginBottom: 16 }}>
				<div style={{ fontSize: 22, marginBottom: 16 }}>
					差異度：{screenshot.diff_ratio}
				</div>
				<Button
					style={{ marginRight: 16 }}
					disabled={updateLatestScreenshot.isLoading}
					onClick={() => {
						if (window.confirm("確定執行此動作?")) {
							updateLatestScreenshot.mutate(
								{},
								{
									onSuccess() {
										Utils.refreshPage();
									},
								}
							);
						}
					}}
				>
					Update
				</Button>
				<Button
					disabled={updateScreenshot.isLoading}
					onClick={() => {
						if (window.confirm("確定執行此動作?")) {
							updateScreenshot.mutate(
								{},
								{
									onSuccess() {
										Utils.refreshPage();
									},
								}
							);
						}
					}}
				>
					OK
				</Button>
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					marginBottom: 16,
				}}
			>
				<Base64Img src={screenshot.base_screenshot} />
				<Base64Img src={screenshot.latest_screenshot} />
			</div>
			<Base64Img src={screenshot.diff_screenshot} />
		</Container>
	);
};

export default MajorOneEditScreenshot;
