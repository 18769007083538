import React, { FC } from "react";
import SelectField, { Props as SelectFieldProps } from "../SelectField";

export type IELTSScoreType =
	| 0
	| 4
	| 4.5
	| 5
	| 5.5
	| 6
	| 6.5
	| 7
	| 7.5
	| 8
	| 8.5
	| 9;

type Option = {
	id: IELTSScoreType;
	value: IELTSScoreType;
	label: IELTSScoreType;
};

const options: Option[] = [
	{
		id: 4,
		value: 4,
		label: 4,
	},
	{
		id: 4.5,
		value: 4.5,
		label: 4.5,
	},
	{
		id: 5,
		value: 5,
		label: 5,
	},
	{
		id: 5.5,
		value: 5.5,
		label: 5.5,
	},
	{
		id: 6,
		value: 6,
		label: 6,
	},
	{
		id: 6.5,
		value: 6.5,
		label: 6.5,
	},
	{
		id: 7,
		value: 7,
		label: 7,
	},
	{
		id: 7.5,
		value: 7.5,
		label: 7.5,
	},
	{
		id: 8,
		value: 8,
		label: 8,
	},
	{
		id: 8.5,
		value: 8.5,
		label: 8.5,
	},
	{
		id: 9,
		value: 9,
		label: 9,
	},
];

type Props = {
	id: string;
	disabled: boolean;
	error: string;
	value?: IELTSScoreType;
	defaultValue?: IELTSScoreType;
	defaultLabel: string;
	onChange: (value: IELTSScoreType) => void;
};

const IELTSScoreSelect: FC<Props> = ({
	id,
	disabled,
	defaultLabel,
	error,
	value,
	defaultValue,
	onChange,
}) => {
	const selectFieldProps: SelectFieldProps = {
		id,
		defaultOptionValue: 0,
		disabled,
		options: options.map(({ id, value, label }) => {
			return {
				id: id.toString(),
				value: value.toString(),
				label: label.toString(),
			};
		}),
		defaultLabel,
		error,
		onChange(e) {
			onChange(parseFloat(e) as IELTSScoreType);
		},
	};

	if (value !== undefined) {
		selectFieldProps.value = value.toString();
	}
	if (defaultValue !== undefined) {
		selectFieldProps.defaultValue = defaultValue.toString();
	}

	return <SelectField {...selectFieldProps} />;
};

export default IELTSScoreSelect;
