import React, { FC } from "react";
import { Button as MaterialButton, ButtonProps } from "@material-ui/core";
import {
	ThemeProvider,
	unstable_createMuiStrictModeTheme,
} from "@material-ui/core/styles";

interface Props extends Omit<ButtonProps, "variant" | "color"> {}

const theme = unstable_createMuiStrictModeTheme({
	palette: {
		primary: {
			main: "rgb(108,135,198)",
		},
	},
});

const Button: FC<Props> = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<MaterialButton {...props} variant="contained" color="primary" />
		</ThemeProvider>
	);
};

export default Button;
