import React, { FC } from "react";
import styled from "styled-components";
import MaterialDrawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import { Divider } from "@material-ui/core";
import Icon from "../Icon/Icon";
import MyUserAvatar from "../MyUserAvatar";
import useList from "./useList";

const Container = styled.div`
	width: 16.5rem;
	height: 100%;
	background-color: #f2f2f2;
`;

const ListItemBox = styled.div`
	display: grid;
	grid-template-columns: 1.25rem auto;
`;

const IconBox = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	svg {
		font-size: 1.25rem;
		color: #818080;
	}
`;

const IconText = styled.div`
	font-size: 1.25rem;
	margin-left: 1.25rem;
	display: flex;
	align-items: center;
`;

type DrawerType = {
	open: boolean;
	onClose: () => void;
};

const Drawer: FC<DrawerType> = ({ open = false, onClose }) => {
	const list = useList();

	return (
		<MaterialDrawer anchor="left" open={open} onClose={onClose}>
			<Container>
				<ListItem button>
					<MyUserAvatar />
				</ListItem>
				{list.map(({ id, isLine, icon, txt = "", onClick }) => {
					if (isLine) {
						return <Divider key={id} />;
					} else {
						return (
							<ListItem
								key={id}
								data-test-id={id}
								button
								onClick={async () => {
									await onClick!();
									onClose();
								}}
							>
								<ListItemBox>
									<IconBox>
										<Icon src={icon!} alt={txt} />
									</IconBox>
									<IconText>{txt}</IconText>
								</ListItemBox>
							</ListItem>
						);
					}
				})}
			</Container>
		</MaterialDrawer>
	);
};

export default Drawer;
