import React, { FC } from "react";
import TextField, { Props as TextFieldProps } from "../TextField";

export interface Props extends Omit<TextFieldProps, "multiline"> {}

const MultilineField: FC<Props> = (props) => {
	return <TextField {...props} multiline />;
};

export default MultilineField;
