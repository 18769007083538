import React, { FC } from "react";
import styled from "styled-components";
import { Props } from "./types";
import getError from "./getError";
import ImageEditor from "../ImageEditor";
import PlaceField from "../PlaceField";
import UniversityOneRankingFields from "./UniversityOneRankingFields";
import InlineEditText from "../InlineEditText";
import TextField from "../TextField";

const Container = styled.div`
	width: 365px;
	display: flex;
	flex-direction: column;

	> *:nth-of-type(n + 3) {
		margin-top: 1.5rem;
	}
`;

const UniversityOne: FC<Props> = ({
	isEdit,
	errors,
	defaultValue,
	onChange,
	onImageChange,
	onPlaceChange,
}) => {
	return (
		<Container>
			{isEdit ? (
				<InlineEditText
					id="name"
					label="名稱"
					style={{ width: 365 }}
					error={getError(errors, "name")}
					defaultValue={defaultValue.name}
					onConfirm={(value) => {
						onChange("name", value);
					}}
				/>
			) : (
				<TextField
					id="name"
					label="名稱"
					disabled={false}
					style={{ width: 365 }}
					error={getError(errors, "name")}
					autoFocus
					readOnly={false}
					defaultValue={defaultValue.name}
					onChange={(value) => {
						onChange("name", value);
					}}
				/>
			)}

			<UniversityOneRankingFields
				isEdit={isEdit}
				errors={errors}
				defaultValue={defaultValue}
				onChange={onChange}
			/>
			<PlaceField
				id="place"
				error={getError(errors, "place_id")}
				defaultValue={{
					place_id: defaultValue.place_id,
					formatted_address: defaultValue.formatted_address,
					short_country: defaultValue.short_country,
					long_country: defaultValue.long_country,
					short_city: defaultValue.short_city,
					long_city: defaultValue.long_city,
					lat: defaultValue.lat,
					lng: defaultValue.lng,
				}}
				onChange={onPlaceChange}
			/>
			<ImageEditor
				label="學校圖片"
				error={getError(errors, "logo")}
				defaultValue={{
					value: defaultValue.logo,
					rotation: defaultValue.logo_rotation,
					zoom: defaultValue.logo_zoom,
					cut: defaultValue.logo_cut,
					positionX: defaultValue.logo_x,
					positionY: defaultValue.logo_y,
				}}
				disabled={false}
				onChange={onImageChange}
			/>
		</Container>
	);
};

export default UniversityOne;
