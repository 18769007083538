import React, { FC } from "react";
import styled from "styled-components";
import Utils from "../../utils";

type Props = {
	value: string;
};

const Container = styled.img`
	width: 220px;
	height: 100px;
`;

const UniversityLogo: FC<Props> = ({ value }) => {
	return <Container src={Utils.getAbsoluteImageUrl(value)} />;
};

export default UniversityLogo;
