import React from "react";
import { RouteComponentProps } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as listPath, title as listTitle } from "./UniversityListRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import UniversityOneAdd from "../components/UniversityOneAdd";

export const path = "/new-university";
export const title = "新增學校";

const UniversityOneAddRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={listTitle}
							preLink={listPath}
							title={title}
						/>
					}
				>
					<UniversityOneAdd />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UniversityOneAddRoute;
