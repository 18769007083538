import _ from "lodash";
import React, { FC } from "react";
import entry_gpa_countries from "../../resources/entry_gpa_countries.json";
import Panel from "../Panel";
import { Grid } from "@material-ui/core";
import BarWithLabelForField from "../BarWithLabelForField";
import TextField from "../TextField";
import OpeningSeasonSelect from "../OpeningSeasonSelect";
import SelectField from "../SelectField";
import MultilineField from "../MultilineField";
import getError from "./getError";
import { Props } from "./types";
import LeftAndRight from "./LeftAndRight";
import GridItem from "./GridItem";
import { MyNumberField } from "./index";
import InlineEditText from "../InlineEditText";
import InlineEditMultiline from "../InlineEditMultiline";
import YesNoButton from "../YesNoButton";

const MajorOneEntryInfoPanel: FC<Props> = ({
	isEdit,
	errors,
	defaultValue: major,
	onChange,
}) => {
	const debounceValue = isEdit ? 500 : 0;

	return (
		<Panel title="入學條件">
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="需要相關背景">
						<YesNoButton
							id="major_any_background"
							checked={major.any_background}
							onChange={(value) => onChange("any_background", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="條件式入學">
						<YesNoButton
							id="major_allow_conditional_offer"
							checked={major.allow_conditional_offer}
							onChange={(value) => onChange("allow_conditional_offer", value)}
						/>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="在校成績">
						<LeftAndRight>
							<SelectField
								id="entry-gpa-country"
								disabled={false}
								options={entry_gpa_countries.map((e) => {
									return {
										id: e.id,
										value: e.id,
										label: e.label,
									};
								})}
								defaultLabel="請選擇"
								value={major.entry_gpa_country}
								error={getError(errors, "entry_gpa_country")}
								onChange={(value) => onChange("entry_gpa_country", value)}
							/>
							<SelectField
								id="entry-gpa-value"
								style={{ marginLeft: "1.5rem" }}
								disabled={false}
								options={
									entry_gpa_countries
										.find((e) => e.id === major.entry_gpa_country)
										?.scales.map((scale) => {
											return {
												id: scale,
												value: scale,
												label: scale,
											};
										}) || []
								}
								defaultLabel="請選擇"
								value={major.entry_gpa_value}
								error={getError(errors, "entry_gpa_value")}
								onChange={(value) => onChange("entry_gpa_value", value)}
							/>
						</LeftAndRight>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="台灣成績">
						<LeftAndRight>
							<MyNumberField
								id="entry_tw_percentile"
								style={{ marginRight: "1.5rem" }}
								min={0}
								max={100}
								step={1}
								disabled={false}
								readOnly={false}
								autoFocus={false}
								label="百分比"
								error={getError(errors, "entry_tw_percentile")}
								defaultValue={major.entry_tw_percentile}
								onChange={_.debounce(
									(value) => onChange("entry_tw_percentile", value),
									debounceValue
								)}
							/>
							<MyNumberField
								id="entry_tw_gpa"
								min={0}
								max={5}
								step={0.01}
								disabled={false}
								readOnly={false}
								autoFocus={false}
								label="GPA"
								error={getError(errors, "entry_tw_gpa")}
								defaultValue={major.entry_tw_gpa}
								onChange={_.debounce(
									(value) => onChange("entry_tw_gpa", value),
									debounceValue
								)}
							/>
						</LeftAndRight>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="開學季">
						<LeftAndRight>
							<OpeningSeasonSelect
								id="open_seasons"
								disabled={false}
								error={getError(errors, "open_seasons")}
								value={major.open_seasons}
								onChange={(value) => onChange("open_seasons", value)}
							/>
						</LeftAndRight>
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="申請截止日">
						{isEdit ? (
							<InlineEditText
								id="apply_deadline"
								placeholder=""
								error={getError(errors, "apply_deadline")}
								defaultValue={major.apply_deadline}
								onConfirm={(value) => onChange("apply_deadline", value)}
							/>
						) : (
							<TextField
								id="apply_deadline"
								disabled={false}
								placeholder=""
								readOnly={false}
								error={getError(errors, "apply_deadline")}
								autoFocus={false}
								defaultValue={major.apply_deadline}
								onChange={(value) => onChange("apply_deadline", value)}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
			<Grid container spacing={1}>
				<GridItem xs={6}>
					<BarWithLabelForField text="其他要求">
						{isEdit ? (
							<InlineEditMultiline
								id="other_entry_requirement"
								placeholder=""
								error={getError(errors, "other_entry_requirement")}
								defaultValue={major.other_entry_requirement}
								onConfirm={(value) =>
									onChange("other_entry_requirement", value)
								}
							/>
						) : (
							<MultilineField
								id="other_entry_requirement"
								disabled={false}
								placeholder=""
								readOnly={false}
								error={getError(errors, "other_entry_requirement")}
								autoFocus={false}
								defaultValue={major.other_entry_requirement}
								onChange={(value) => onChange("other_entry_requirement", value)}
							/>
						)}
					</BarWithLabelForField>
				</GridItem>
			</Grid>
		</Panel>
	);
};

export default MajorOneEntryInfoPanel;
