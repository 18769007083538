import React, { FC } from "react";
import MaterialSlider from "@material-ui/core/Slider";

type Props = {
	min: number;
	max: number;
	step: number;
	value: number;
	onChange: (value: number) => void;
	onChangeCommitted: (value: number) => void;
};

const Slider: FC<Props> = ({
	min,
	max,
	step,
	value,
	onChange,
	onChangeCommitted,
}) => {
	return (
		<MaterialSlider
			min={min}
			max={max}
			step={step}
			value={value}
			onChange={(event, value) => onChange(value as number)}
			onChangeCommitted={(event, value) => onChangeCommitted(value as number)}
		/>
	);
};

export default Slider;
