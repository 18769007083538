function getLongCountry(result: google.maps.GeocoderResult): string {
	return (
		result.address_components
			.filter((e) => e.types.length === 2)
			.find((e) => {
				return e.types[0] === "country" && e.types[1] === "political";
			})?.long_name || ""
	);
}

export default getLongCountry;
