import React from "react";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";
import { RouteComponentProps } from "@reach/router";
import MainLayout from "../components/MainLayout";
import LayoutContent from "../components/MainLayout/LayoutContent";
import UniversityList from "../components/UniversityList";

export const path = "/uni";
export const title = "學校管理";

const UniversityListRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<LayoutContent title={title} data-test-id={path}>
					<UniversityList />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UniversityListRoute;
