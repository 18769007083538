const useFileReader = () => {
	return async (
		file: File,
		onProgress:
			| ((this: FileReader, ev: ProgressEvent<FileReader>) => any)
			| null
	) => {
		return new Promise((done, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				done(reader.result);
			};

			reader.onabort = reject;
			reader.onerror = reject;
			reader.onprogress = onProgress;
			reader.readAsDataURL(file);
		});
	};
};

export default useFileReader;
