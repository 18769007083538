import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "@reach/router";
import defaultValue from "./defaultData.json";
import { path as listPath } from "../../routes/UniversityListRoute";
import useButtonDisabled from "./useButtonDisabled";
import Button from "../Button";
import {
	UniversityErrorsType,
	UniversityType,
} from "../../types/universityType";
import UniversityOne from "../UniversityOne";
import useSubmitMutation from "./useSubmitMutation";
import { useFindAllUniversity } from "../UniversityList/UniversityList";

const emptyError: UniversityErrorsType = {
	name: "",
	logo: "",
	logo_cut: "",
	logo_rotation: "",
	logo_x: "",
	logo_y: "",
	logo_zoom: "",
	ranking_qs: "",
	ranking_guardian: "",
	ranking_complete_guide: "",
	ranking_us_news: "",
};

const UniversityOneAdd = () => {
	const navigate = useNavigate();
	const [university, setUniversity] = useState<UniversityType>(
		defaultValue as UniversityType
	);
	const [errors, setErrors] = useState<UniversityErrorsType>({});
	const isDisabled = useButtonDisabled(errors);
	const addMutation = useSubmitMutation();
	const findAll = useFindAllUniversity();

	return (
		<>
			<UniversityOne
				isEdit={false}
				errors={errors}
				defaultValue={university}
				onPlaceChange={(place) => {
					setUniversity({
						...university,
						...place,
					});
					setErrors((_) => emptyError);
				}}
				onImageChange={(image) => {
					setUniversity({
						...university,
						logo: image.value,
						logo_cut: image.cut,
						logo_rotation: image.rotation,
						logo_x: image.positionX,
						logo_y: image.positionY,
						logo_zoom: image.zoom,
					});
					setErrors((_) => emptyError);
				}}
				onChange={(field, value) => {
					setUniversity({
						...university,
						[field]: value,
					});
					setErrors((_) => emptyError);
				}}
			/>
			<div style={{ display: "flex", marginTop: 32 }}>
				<Button
					style={{ marginRight: "1rem" }}
					disabled={isDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							await navigate(listPath);
						}
					}}
				>
					取消
				</Button>
				<Button
					disabled={isDisabled}
					onClick={() => {
						if (window.confirm("是否確定送出?")) {
							addMutation.mutate(university, {
								async onSuccess() {
									await findAll.refetch();
									await navigate(listPath);
								},
								async onError(data) {
									const errors = data.response.data.errors;
									setErrors(errors);
									if (!_.isEmpty(errors)) {
										window.alert(_.values(errors).join("\n").toString());
									}
								},
							});
						}
					}}
				>
					送出
				</Button>
			</div>
		</>
	);
};

export default UniversityOneAdd;
