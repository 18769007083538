import React, { Fragment } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import useIsLogin from "../hooks/useIsLogin";
import { path as listPath, title as listTitle } from "./UniversityListRoute";
import LoginRoute from "./LoginRoute";
import MainLayout from "../components/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import LayoutContent from "../components/MainLayout/LayoutContent";
import { useFindOne } from "../queries/resource.query";
import UniversityOneEdit from "../components/UniversityOneEdit";
import { UniversityOneType } from "../types/universityType";

export const prefixPath = "/university";
export const path = `${prefixPath}/:id`;

const resource = "university";

const UniversityOneEditRoute = (ignored: RouteComponentProps) => {
	const isLogin = useIsLogin();
	const { id } = useParams();
	const record = useFindOne<UniversityOneType>(resource, id);

	if (!isLogin) {
		return <LoginRoute />;
	} else if (record.isLoading) {
		return <Fragment />;
	} else {
		return (
			<MainLayout>
				<LayoutContent
					data-test-id={path}
					title={
						<Breadcrumbs
							preTitle={listTitle}
							preLink={listPath}
							title={record.data!.name}
						/>
					}
				>
					<UniversityOneEdit />
				</LayoutContent>
			</MainLayout>
		);
	}
};

export default UniversityOneEditRoute;
