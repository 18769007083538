import React, { CSSProperties, FC } from "react";

type Props = {
	src: string;
	alt: string;
	style?: CSSProperties;
};

const Icon: FC<Props> = ({ src, alt, style = {} }) => {
	return (
		<img style={{ ...style, width: 26, height: 26 }} src={src} alt={alt} />
	);
};

export default Icon;
