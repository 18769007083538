import React, { FC } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";

const Icon = styled(DeleteIcon)`
	color: #f2abab;
`;

type Props = {
	className?: string;
	disabled?: boolean;
	onClick: (e: any) => void;
};

const BinIcon: FC<Props> = ({
	className,
	disabled = false,
	onClick,
}: Props) => {
	return (
		<IconButton
			id="hello"
			className={className}
			component="span"
			size="medium"
			onClick={(e: any) => {
				if (!disabled) {
					onClick(e);
				}
			}}
		>
			<Icon />
		</IconButton>
	);
};

export default BinIcon;
